import { forwardRef } from "react";

import FormField from "../form-field";

import styles from "./textField.module.css";
import Label from "./label";
import HelperText from "./helper-text";
import InputAdornment from "./input-adornment";
import { TextFieldProps } from "./types";

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    startAdornment,
    endAdornment,
    label,
    id,
    helperText,
    placeholder,
    multiline,
    rows,
    color = "default",
    inputSize = "md",
    clasName,
    ...other
  } = props;

  return (
    <div className={clasName}>
      <div className={styles.root}>
        {startAdornment && (
          <InputAdornment position="start" size={inputSize}>
            {startAdornment}
          </InputAdornment>
        )}
        {endAdornment && (
          <InputAdornment position="end" size={inputSize}>
            {endAdornment}
          </InputAdornment>
        )}
        <FormField
          ref={ref}
          id={id}
          placeholder={label ? " " : placeholder}
          inputSize={inputSize}
          color={color}
          multiline={multiline}
          rows={rows}
          startAdornment={Boolean(startAdornment)}
          endAdornment={Boolean(endAdornment)}
          {...other}
        />
        {label && (
          <Label htmlFor={id} size={inputSize} color={color}>
            {label}
          </Label>
        )}
      </div>
      {helperText && <HelperText color={color}>{helperText}</HelperText>}
    </div>
  );
});

TextField.displayName = "TextField";

export default TextField;
