import { Port, PortsMap } from "@/global-types/ports";

export const createPortsMap = (ports: Port[]): PortsMap => {
  return ports.reduce(
    (acc, port) => ({
      ...acc,
      [port.ferryOwnLocationID]: port,
    }),
    {} as PortsMap,
  );
};

const matchesInput = (portsData: Port, searchTerm: string): boolean => {
  const portName = portsData.locationName.toLowerCase();
  const searchTermToLowerCase = searchTerm.toLowerCase();

  const alternativeNames = portsData.alternativeNames
    ? portsData.alternativeNames
        .toLowerCase()
        .split(",")
        .map((name) => name.trim())
    : [];

  return (
    portName.includes(searchTermToLowerCase) ||
    alternativeNames.some((name) => name.includes(searchTermToLowerCase))
  );
};

export const filterSuggestions = (
  searchTerm: string,
  portsData: Port[],
): Port[] => {
  const portsMap = createPortsMap(portsData);

  return portsData.filter((port) => {
    if (!port.childPorts.length) {
      return matchesInput(port, searchTerm);
    }

    return (
      matchesInput(port, searchTerm) ||
      port.childPorts.some((portId) => {
        const childPort = portsMap[portId];
        return childPort ? matchesInput(childPort, searchTerm) : false;
      })
    );
  });
};

export const collectPortsInNestedLists = (portsData: Port[]): string[] => {
  const portsMap = createPortsMap(portsData);
  const collectedPorts = new Set<string>();

  portsData.forEach((port) => {
    port.childPorts.forEach((portId) => {
      const childPort = portsMap[portId];
      if (childPort) {
        collectedPorts.add(childPort.locationName);
      }
    });
  });

  return Array.from(collectedPorts);
};
