import { sendGTMEvent } from "@next/third-parties/google";

export const beaconIsEnabled = () => {
  if (typeof window === "undefined") return false;
  return Boolean(window.Trengo);
};

export const openBeacon = () => {
  const waitForGTM = setInterval(() => {
    if (typeof window.dataLayer !== "undefined") {
      clearInterval(waitForGTM);

      if (!beaconIsEnabled()) {
        window.dataLayer.push({ event: "loadBeacon" });
      }
      window.dataLayer.push({ event: "openChat" });

      try {
        sendGTMEvent({ event: "openChat" });
      } catch (error) {
        console.error("Error sending GTM event:", error);
      }
    }
  }, 100);

  // Timeout after 5 seconds to avoid infinite loop
  setTimeout(() => clearInterval(waitForGTM), 5000);
};
