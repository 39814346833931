import React from "react";

import Facebook from "./icons/facebook";
import Instagram from "./icons/instagram";
import TikTok from "./icons/tik-tok";
import LinkedIn from "./icons/linked-in";
import Youtube from "./icons/youtube";
import Threads from "./icons/threads";
import { SOCIAL_MEDIA_LINKS_TITLE } from "./constants";
import { Locale } from "@/i18n-config";

interface SocialLink {
  id: keyof (typeof SOCIAL_MEDIA_LINKS_TITLE)[Locale];
  Icon: React.FC;
  href: string;
}

const FollowUs = ({ lang }: { lang: Locale }) => {
  const socialLinks: SocialLink[] = [
    {
      id: "facebook",
      Icon: Facebook,
      href: "https://www.facebook.com/Ferryscanner",
    },
    {
      id: "instagram",
      Icon: Instagram,
      href: "https://www.instagram.com/ferryscanner/",
    },
    {
      id: "tiktok",
      Icon: TikTok,
      href: "https://www.tiktok.com/@ferryscanner",
    },
    {
      id: "linkedin",
      Icon: LinkedIn,
      href: "https://www.linkedin.com/company/ferryscanner",
    },
    {
      id: "youtube",
      Icon: Youtube,
      href: "https://www.youtube.com/@ferryscanner",
    },
    {
      id: "threads",
      Icon: Threads,
      href: "https://www.threads.net/@ferryscanner",
    },
  ];

  return (
    <div className="mt-6 flex items-center gap-6">
      {socialLinks.map(({ Icon, href, id }) => {
        const title = SOCIAL_MEDIA_LINKS_TITLE[lang][id];
        return (
          <a
            key={id}
            href={href}
            target="_blank"
            className="text-default"
            title={title}
          >
            <Icon />
            <span className="sr-only">{title}</span>
          </a>
        );
      })}
    </div>
  );
};

export default FollowUs;
