"use client";

import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { getUser } from "@/data/user/queries";
import { User } from "@/global-types/user";
import { Translations } from "@/global-types/translations";
import Skeleton from "@/components/skeleton";
import UserIcon from "@/icons/user";

import { getInitials } from "./utils";
import styles from "./user-account.module.css";
import { USER_AUTHENTICATION_PROMPT } from "./constants";

const Dropdown = dynamic(() => import("@/components/dropdown"), { ssr: false });

interface Props {
  translations: Translations | undefined;
  lang: string;
}

const UserAccount = ({ lang, translations }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    window.location.reload();
  };

  const getUserFromLocalStorage = async () => {
    const user = localStorage.getItem("user");
    const { uuid, session_token } = (user && JSON.parse(user)) || {};

    if (!uuid || !session_token) {
      setIsLoading(false);
      return;
    }

    return await getUser({ uuid, sessionToken: session_token });
  };

  useEffect(() => {
    getUserFromLocalStorage().then((data) => {
      if (!data) {
        localStorage.removeItem("user");
        localStorage.removeItem("session_token");
        return;
      }
      setUser(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Skeleton style={{ width: 30, height: 30, borderRadius: 100 }} />;
  }

  return (
    <>
      {!user ? (
        <a
          className="p-2"
          href={`/${lang}/user`}
          title={USER_AUTHENTICATION_PROMPT[lang]}
        >
          <UserIcon />
          <span className="sr-only">{USER_AUTHENTICATION_PROMPT[lang]}</span>
        </a>
      ) : (
        <div className={styles.userAccount}>
          <button onClick={handleClick}>
            <div className={styles.avatar} style={{ cursor: "pointer" }}>
              <span className="text-sm font-bold text-white">
                {getInitials(user)}
              </span>
            </div>
          </button>
          <Dropdown open={open} anchorEl={anchorEl} onClose={handleClose}>
            <div className="flex flex-1 flex-col items-center gap-6 overflow-y-auto bg-gray-50 p-4">
              <a className="text-primary" href={`/${lang}/user/dahsboard`}>
                {translations?.tp_dashboard_dropdown || "View dashboard"}
              </a>
              <button className="text-primary" onClick={handleLogout}>
                {translations?.logout || "Logout"}
              </button>
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserAccount;
