import { Locale } from "@/i18n-config";

export const USER_AUTHENTICATION_PROMPT: Record<Locale, string> = {
  en: "Log in or register for a Ferryscanner account",
  it: "Accedi o registrati per un account Ferryscanner",
  el: "Συνδέσου ή δημιούργησε λογαριασμό Ferryscanner",
  hr: "Prijavi se ili registriraj za Ferryscanner račun",
  es: "Inicia sesión o regístrate para una cuenta de Ferryscanner",
  pt: "Inicia sessão ou regista-te para uma conta Ferryscanner",
  fr: "Connecte-toi ou inscris-toi pour un compte Ferryscanner",
  pl: "Zaloguj się lub zarejestruj konto Ferryscanner",
  de: "Melde dich an oder registriere ein Ferryscanner-Konto",
  da: "Log ind eller registrer dig for en Ferryscanner-konto",
  no: "Logg inn eller registrer deg for en Ferryscanner-konto",
  tr: "Ferryscanner Hesabı için Giriş Yap veya Kaydol",
  nl: "Log in of registreer voor een Ferryscanner-account",
  fi: "Kirjaudu sisään tai rekisteröidy Ferryscanner-tilille",
  sv: "Logga in eller registrera dig för ett Ferryscanner-konto",
  sq: "Hyni ose regjistrohu për një llogari Ferryscanner",
  et: "Logi sisse või registreeri end Ferryscanneri kontole",
  ja: "Ferryscannerアカウントにログインまたは登録してください",
  ko: "Ferryscanner 계정에 로그인하거나 등록하세요",
  zh: "登录或注册 Ferryscanner 帐户",
};
