import { RootState } from "@/store/types";

const nameSpace = "fs";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem(nameSpace);
    return serializedState ? JSON.parse(serializedState) : {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const saveState = (state: RootState) => {
  try {
    const currentState = loadState();
    const serializedState = JSON.stringify({ ...currentState, ...state });
    sessionStorage.setItem(nameSpace, serializedState);
  } catch (err) {
    console.error(err);
  }
};
