import dayjs from "dayjs";
import { RecentSearch } from "./types";
import { NAMESPACE } from "./constants";

export const saveRecentSearch = (newSearch: RecentSearch) => {
  const storedData: RecentSearch[] = JSON.parse(
    localStorage.getItem(NAMESPACE) || "[]",
  );

  // Check if a search with the same departurePorts and arrivalPorts exists
  const existingIndex = storedData.findIndex(
    (search) =>
      search.departurePorts === newSearch.departurePorts &&
      search.arrivalPorts === newSearch.arrivalPorts,
  );

  if (existingIndex !== -1) {
    // Replace the existing search with the new one
    storedData[existingIndex] = newSearch;
  } else {
    // Add the new search
    storedData.push(newSearch);
  }

  // Sort by date (newest first) and keep only the five newest entries
  const sortedData = storedData
    .sort((a, b) => (dayjs(b.dates).isAfter(dayjs(a.dates)) ? -1 : 1))
    .slice(0, 5);

  // Save back to local storage
  localStorage.setItem(NAMESPACE, JSON.stringify(sortedData));
};
