"use client";

import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import clsx from "clsx";
import { chunk } from "lodash";
import useEmblaCarousel from "embla-carousel-react";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";

import styles from "./trending-destinations.module.css";
import { DestinationsItem } from "../../types";

type Props = {
  destinations: DestinationsItem[];
};
const Destinations: React.FC<Props> = ({ destinations }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    dragFree: true,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [distanceToRightEdge, setDistanceToRightEdge] = useState(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const widthToEdge = window.innerWidth - rect.right;
    setDistanceToRightEdge(widthToEdge);
  }, []);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const slides = chunk(destinations, 4);

  return (
    <>
      <div
        ref={containerRef}
        className="-mx-4"
        style={{ width: distanceToRightEdge }}
      >
        <div ref={emblaRef} className="w-full px-4 lg:overflow-hidden">
          <div className="-ml-4 flex">
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                className={styles.slide}
                style={{ flex: "0 0 752px" }}
              >
                {slide.map((item, index) => {
                  const itemClasses = clsx(styles.card, {
                    [styles.row_span]: index % 4 === 2, // Makes second item span two rows
                    [styles.col_span]: index % 4 === 3, // Makes thirditem span two rows
                  });

                  const contentClasses = clsx({
                    [styles.card_content]: item.destination_image,
                    [styles.card_placeholder]: !item.destination_image,
                  });

                  return (
                    <div key={index} className={itemClasses}>
                      {item.destination_image ? (
                        <Image
                          src={item.destination_image}
                          alt={item.destination_title}
                          fill
                          sizes="50vw"
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <div />
                      )}
                      <div className={contentClasses}>
                        <h3 className={styles.card_title}>
                          <a href={item.destination_url}>
                            <span className="text-xl font-bold">
                              {item.destination_title}
                            </span>{" "}
                            <span className="text-sm">
                              {item.destination_location}
                            </span>
                          </a>
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.carousel_buttons}>
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </div>
    </>
  );
};

export default Destinations;
