import React from "react";
import GlobeIcon from "@/icons/globe";
import { SUPPORTED_LANGUAGES } from "@/constants/language";
import { Translations } from "@/global-types/translations";

interface Props {
  setLanguage: (lang: string) => void;
  language: string;
  translations: Translations | undefined;
}

const Language = ({ translations, setLanguage, language }: Props) => {
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-2">
        <GlobeIcon />
        <label htmlFor="language" className="font-bold">
          {translations?.language_options || "Language Options"}
        </label>
        {language !== "en" && (
          <>
            • {translations?.switch_to || "Switch to"}
            <button
              className="text-blue-500 hover:underline"
              onClick={() => setLanguage("en")}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            >
              English
            </button>
          </>
        )}
      </div>
      <select
        id="language"
        name="language"
        value={language}
        onChange={handleLanguageChange}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        {Object.keys(SUPPORTED_LANGUAGES).map((languageKey) => (
          <option key={languageKey} value={languageKey}>
            {SUPPORTED_LANGUAGES[languageKey].label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Language;
