export const CURRENCY_SYMBOL: { [key: string]: string } = {
  EUR: "€",
  AED: "د.إ",
  ARS: "$",
  AUD: "$",
  BGN: "лв",
  BRL: "R$",
  CAD: "$",
  CHF: "",
  CLP: "$",
  CZK: "Kč",
  DKK: "kr",
  EGP: "£",
  GBP: "£",
  HKD: "$",
  HUF: "Ft",
  ILS: "₪",
  INR: "₹",
  MXN: "$",
  NOK: "kr",
  NZD: "$",
  PLN: "zł",
  RON: "lei",
  RSD: "Дин.",
  SAR: "﷼",
  SEK: "kr",
  SGD: "S$",
  TRY: "₺",
  USD: "$",
  ZAR: "R",
};
