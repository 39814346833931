import { Port } from "@/global-types/ports";

const getPortData = (portID: string, portsData?: Port[]) =>
  portsData?.find((port) => port.ferryOwnLocationID === portID);

export const getPortAbbr = (portID: string, portsData?: Port[]) =>
  getPortData(portID, portsData)?.locationAbbr || "";

export const getPortLocationName = (portID: string, portsData?: Port[]) =>
  getPortData(portID, portsData)?.locationName || "";

export const getFerryLocationId = ({
  abbr,
  portsData,
}: {
  abbr: string;
  portsData: Port[];
}) =>
  portsData.find(({ locationAbbr }) => locationAbbr === abbr)
    ?.ferryOwnLocationID || "";
