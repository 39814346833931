import React, { useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import Cookies from "js-cookie";

import { Translations } from "@/global-types/translations";
import { LANGUAGE_FERRY_TERM_COMBINATIONS } from "@/constants/language";

import Header from "./components/header";
import Language from "./components/language";

import styles from "./content.module.css";
import Currency from "./components/currency";

interface Props {
  onClose: () => void;
  lang: string;
  availableCurrencies: string[];
  currency: string;
  setCurrency: (currency: string) => void;
  translations: Translations | undefined;
}

const Content = ({
  onClose,
  lang: initialLang,
  availableCurrencies,
  currency,
  setCurrency,
  translations,
}: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const [language, setLanguage] = useState(initialLang);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currencyDetails = { iso_currency: currency };
    sessionStorage.setItem("currency-storage", JSON.stringify(currencyDetails));

    Cookies.set("language", language);
    const isMobileAppPage = pathname.includes("/app");
    const newPath = isMobileAppPage
      ? `/${language}/app`
      : `/${language}/${LANGUAGE_FERRY_TERM_COMBINATIONS[language]}`;
    router.push(newPath);
    router.refresh();
    onClose();
  };

  return (
    <div tabIndex={-1} className={styles.root}>
      <div className={styles.content}>
        <Header onClose={onClose} translations={translations} />
        <form onSubmit={handleSubmit}>
          <Language
            translations={translations}
            setLanguage={setLanguage}
            language={language}
          />
          <Currency
            setCurrency={setCurrency}
            currency={currency}
            availableCurrencies={availableCurrencies}
            translations={translations}
          />

          <button
            type="submit"
            className="btn btn-accent btn-lg w-full"
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            {translations?.save || "Save"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Content;
