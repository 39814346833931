"use client";

import React from "react";
import styles from "./about-us-desktop.module.css";

const Polygon = () => {
  return (
    <svg
      fill="none"
      viewBox="0 0 533 536"
      width={480}
      height={480}
      className={styles.polygon}
    >
      <path
        fill="url(#about-us-polygon)"
        d="M139.271 494.829a130.002 130.002 0 0 1-83.316-76.056L10.278 305.11a130 130 0 0 1 7.516-112.559L78.18 85.971a130 130 0 0 1 92.689-64.302l120.976-19.24A130 130 0 0 1 399.91 34.804l90.47 82.587a129.997 129.997 0 0 1 42.065 104.674l-8.162 122.225a130 130 0 0 1-55.61 98.151l-100.648 69.824a130 130 0 0 1-111.41 17.719l-117.344-35.155Z"
      />
      <defs>
        <linearGradient
          id="about-us-polygon"
          x1={79.3}
          x2={464.204}
          y1={476.862}
          y2={55.22}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E4FF" stopOpacity={0.6} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Polygon;
