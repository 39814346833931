"use client";

import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";
import { saveRecentSearch } from "@/persistent-storage/recent-searches";
import { Translations } from "@/global-types/translations";
import { getPortAbbr } from "@/utils/port-utils";
import { Port } from "@/global-types/ports";

type Props = {
  portsData: Port[];
  translations: Translations | undefined;
};

const SearchButton = ({ translations, portsData }: Props) => {
  const routes = useSelector(selectors.getRoutes);
  const tripType = useSelector(selectors.getTripType);

  const isDisabled = routes.some(
    (route) => !route.departurePortId || !route.arrivalPortId || !route.date,
  );

  const handleClick = () => {
    const departurePorts = routes.map((route) => route.departurePortId);
    const arrivalPorts = routes.map((route) => route.arrivalPortId);
    const dates = routes.map((route) => route.date);

    saveRecentSearch({
      tripType,
      departurePorts: departurePorts.join(","),
      arrivalPorts: arrivalPorts.join(","),
      dates: dates.join(","),
    });

    const departureAbbr = departurePorts
      .map((portId) => getPortAbbr(portId, portsData))
      .join(",");
    const arrivalAbbr = arrivalPorts
      .map((portId) => getPortAbbr(portId, portsData))
      .join(",");
    const dateString = dates.join(",");

    const searchURL = `${window.location.pathname}/results#search/dep/${departureAbbr}/arr/${arrivalAbbr}/date/${dateString}`;
    window.location.href = searchURL;
  };

  return (
    <button
      className="btn btn-accent btn-lg w-full"
      disabled={isDisabled}
      onClick={handleClick}
    >
      {translations?.search || "Search"}
    </button>
  );
};

export default SearchButton;
