"use client";

import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@/store/routes";
import { TripType } from "@/store/routes/types";

import styles from "./trip-types.module.css";

interface Item {
  value: TripType;
  label: string;
}
interface Props {
  items: Item[];
  className?: string;
}

const TripTypes = ({ items, className }: Props) => {
  const dispatch = useDispatch();
  const selected = useSelector(selectors.getTripType);

  const handleClick = (value: TripType) => {
    if (value === selected) return;

    dispatch(actions.changeTripType(value));
  };
  return (
    <div className={clsx(styles.root, className)}>
      {items.map(({ value, label }) => (
        <button
          key={value}
          className={clsx(styles.item, {
            "btn-primary": selected === value,
          })}
          onClick={() => handleClick(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default TripTypes;
