import React from "react";

import CurrencyIcon from "@/icons/currency";
import { CURRENCY_SYMBOL } from "@/constants/currency";
import { Translations } from "@/global-types/translations";

interface Props {
  availableCurrencies: string[];
  currency: string;
  setCurrency: (currency: string) => void;
  translations: Translations | undefined;
}

const Currency = ({
  availableCurrencies,
  currency,
  setCurrency,
  translations,
}: Props) => {
  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value); // Update the selected currency
  };

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-2">
        <CurrencyIcon />
        <label htmlFor="currency" className="font-bold">
          {translations?.currency_options || "Currency Options"}
        </label>
      </div>
      <select
        id="currency"
        name="currency"
        value={currency}
        onChange={handleCurrencyChange}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        {availableCurrencies.map((currencyCode) => (
          <option key={currencyCode} value={currencyCode}>
            {currencyCode} - {CURRENCY_SYMBOL[currencyCode]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Currency;
