import getOr from "lodash/fp/getOr";
import { PersistentReducer, RootState } from "@/store/types";
import { loadState } from "./persistent-storage";

export const createSlice = (state: RootState, constants: PersistentReducer) => {
  const reducerName = constants.REDUCER_NAME;
  const persistentKeys = constants.PERSISTENT_PROPS;
  const slice: Record<string, string | number | undefined> = {};
  Object.values(persistentKeys).forEach((keyStringValue) => {
    slice[keyStringValue] = getPersistentValue(
      reducerName,
      keyStringValue,
      state,
    );
  });

  return {
    [reducerName]: slice,
  };
};

export const getPersistentValue = (
  reducerName: string,
  keyStringValue: string,
  state = loadState(),
) => {
  return getOr(undefined, [reducerName, keyStringValue])(state);
};
