"use client";

import React from "react";
import Image from "next/image";
import useEmblaCarousel from "embla-carousel-react";

import Title from "../title";

import TravelInsurance from "./icons/insurance.svg";
import Transfers from "./icons/transfer.svg";
import Parking from "./icons/parking.svg";
import Polygon from "./polygon";

import styles from "./upsell.module.css";

import { Upsells } from "../../types";

type Props = {
  isMobile: boolean;
  data: Upsells;
};

const icons = [Parking, Transfers, TravelInsurance];

const Upsell: React.FC<Props> = ({ data, isMobile }) => {
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    loop: false,
    containScroll: "trimSnaps",
    slidesToScroll: 1,
  });

  return (
    <section className={styles.root}>
      <div className="container relative">
        {!isMobile && (
          <div className={styles.polygon}>
            <Polygon />
          </div>
        )}
        <Title className="mb-6 text-center">{data.upsells_title}</Title>
        <div ref={emblaRef} className={styles.carousel}>
          <div className="flex gap-4">
            {data.upsells_items.map((item, index) => {
              const src = icons[index];
              return (
                <div key={index} className={styles.card}>
                  <Image src={src} alt="" />
                  <div className={styles.card_content}>
                    <h3 className={styles.card_title}>
                      <a
                        href={item.upsell_call_to_action_link}
                        rel="nofollow"
                        target="_blank"
                      >
                        {item.upsell_title}
                      </a>
                    </h3>
                    <p>{item.upsell_description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Upsell;
