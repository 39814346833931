"use client";

import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";

import {
  findCalendarIndexByDateKey,
  generateOneYearFromCurrentMonth,
} from "./utils";

import Month from "./month";

import styles from "./calendar.module.css";
import useSelectedDates from "./useSelectedDates";
import { Translations } from "@/global-types/translations";

interface Props {
  isMobile?: boolean;
  routeIndex: number;
  minDate: string;
  maxDate?: string;
  showTripTypes?: boolean;
  onClose?: () => void;
  translations: Translations | undefined;
}

const Calendar = ({
  isMobile,
  minDate,
  maxDate,
  routeIndex,
  onClose,
  translations,
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    axis: "y",
    breakpoints: {
      "(min-width: 768px)": { axis: "x" }, // 768px screens and up will apply { axis: 'x' }
    },
  });
  const selectedDates = useSelectedDates(routeIndex);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const calendars = generateOneYearFromCurrentMonth(minDate);
  const scrollToIndex = findCalendarIndexByDateKey(calendars, selectedDates[0]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(scrollToIndex, true);
    }
  }, [emblaApi, scrollToIndex]);

  return (
    <div className="relative flex-1 overflow-hidden">
      <div className="h-full overflow-hidden" ref={emblaRef}>
        <div className={styles.embla_container}>
          {calendars.map((month, index) => {
            return (
              <div key={index} className={styles.embla_slide}>
                <Month
                  key={index}
                  isMobile={isMobile}
                  month={month}
                  minDate={minDate}
                  maxDate={maxDate}
                  routeIndex={routeIndex}
                  onClose={onClose}
                  translations={translations}
                />
              </div>
            );
          })}
        </div>
      </div>
      <PrevButton
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        className="absolute left-0 top-0 hidden bg-gray-50 md:block"
      />
      <NextButton
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        className="absolute right-0 top-0 hidden bg-gray-50 md:block"
      />
    </div>
  );
};

export default Calendar;
