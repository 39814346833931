"use client";

import { createPortal } from "react-dom";
import React, { useEffect, useState } from "react";

import { Translations } from "@/global-types/translations";
import { SUPPORTED_LANGUAGES } from "@/constants/language";
import { CURRENCY_SYMBOL } from "@/constants/currency";

import Content from "./content";
import Skeleton from "@/components/skeleton";
import ArrowDown from "@/icons/arrow-down";

interface Props {
  translations: Translations | undefined;
  lang: string;
  availableCurrencies: string[];
  defaultCurrency: string;
}

const Actions = ({
  translations,
  lang,
  availableCurrencies,
  defaultCurrency,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(true);

  useEffect(() => {
    const currencyStorage = JSON.parse(
      sessionStorage.getItem("currency-storage") ?? "{}",
    );
    const preferredCurrency = currencyStorage.iso_currency as string;

    if (preferredCurrency) {
      setCurrency(preferredCurrency);
    }
    setIsCurrencyLoading(false);
  }, []);

  return (
    <>
      {open &&
        createPortal(
          <Content
            onClose={() => setOpen(false)}
            currency={currency}
            setCurrency={setCurrency}
            availableCurrencies={availableCurrencies}
            lang={lang}
            translations={translations}
          />,
          document.body,
        )}
      <button
        className="btn btn-text-primary justify-start md:justify-center"
        onClick={() => setOpen(true)}
      >
        {SUPPORTED_LANGUAGES[lang].label}
        <span className="ml-2">
          <ArrowDown />
        </span>
      </button>
      <button
        className="btn btn-text-primary justify-start md:justify-center"
        onClick={() => setOpen(true)}
      >
        {isCurrencyLoading ? (
          <Skeleton style={{ width: 60, height: 30, marginLeft: "auto" }} />
        ) : (
          `${currency} • ${CURRENCY_SYMBOL[currency]}`
        )}
        <span className="ml-2">
          <ArrowDown />
        </span>
      </button>
    </>
  );
};

export default Actions;
