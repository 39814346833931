"use client";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";
import { getPortLocationName } from "@/utils/port-utils";

import { Port } from "@/global-types/ports";

import { getMatchingPorts } from "../utils";
import DateField from "../date-field";
import SearchButton from "../search-button";
import Autocomplete from "../autocomplete";
import Swap from "../swap";

import { getCalendarButtonLabel } from "./utils";

import styles from "./single-trip.module.css";
import { Translations } from "@/global-types/translations";

interface Props {
  isMobile: boolean;
  portsData: Port[];
  translations: Translations | undefined;
}

const SingleTrip = ({ portsData, translations, isMobile }: Props) => {
  const routes = useSelector(selectors.getRoutes);
  const { departurePortId, arrivalPortId } = routes[0];
  const ports = [departurePortId, arrivalPortId];

  return (
    <div className={styles.root}>
      <div className={styles.input_col}>
        <div className={styles.input_grid}>
          <Autocomplete
            portsData={portsData}
            isMobile={isMobile}
            id="from-0"
            placeholder={translations?.dep || "From"}
            routeType="departure"
            routeIndex={0}
            initialPortLocationName={getPortLocationName(
              departurePortId,
              portsData,
            )}
            translations={translations}
          />
          <Autocomplete
            portsData={getMatchingPorts(portsData, departurePortId)}
            isMobile={isMobile}
            id="to-0"
            placeholder={translations?.arr || "To"}
            routeType="arrival"
            routeIndex={0}
            disabled={!departurePortId}
            initialPortLocationName={getPortLocationName(
              arrivalPortId,
              portsData,
            )}
            translations={translations}
          />
          <Swap routeIndex={0} className={styles.swap_button} />
        </div>
      </div>
      <div className={styles.date_col}>
        <DateField
          label={
            getCalendarButtonLabel(routes) ||
            translations?.select_dates ||
            "Select Dates"
          }
          routeIndex={0}
          minDate={dayjs().format("YYYY-MM-DD")}
          disabled={!ports.every((port) => port)}
          isMobile={isMobile}
          translations={translations}
        />
      </div>
      <div className={styles.button_col}>
        <SearchButton translations={translations} portsData={portsData} />
      </div>
    </div>
  );
};

export default SingleTrip;
