import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

import { Calendar } from "./types";

export const generateCalendar = (year: number, month: number): Calendar => {
  const startOfMonth: Dayjs = dayjs(`${year}-${month}-01`); // Start of the month
  const startDay: number = startOfMonth.startOf("month").day(); // Get the first day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const daysInMonth: number = startOfMonth.daysInMonth(); // Get total days in the month

  // Create an empty array to represent the calendar grid (weeks x days)
  const calendar: Calendar = [];

  // Track the current day of the month we're on
  let currentDay: number = 1;

  // We loop through weeks (rows)
  for (let week = 0; week < 6; week++) {
    const weekRow: (string | "")[] = [];

    // Loop through days in the week (Sunday to Saturday)
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
      // Fill in the days that are part of the previous month as empty
      if (week === 0 && dayOfWeek < startDay) {
        weekRow.push("");
      } else if (currentDay > daysInMonth) {
        // Stop adding days once we surpass the number of days in the month
        weekRow.push("");
      } else {
        // Add the current day of the month as a full date string
        const fullDate: string = dayjs(`${year}-${month}-${currentDay}`).format(
          "YYYY-MM-DD",
        );
        weekRow.push(fullDate);
        currentDay++;
      }
    }

    // Add the week row to the calendar
    calendar.push(weekRow);

    // Stop if we've already added all the days in the month
    if (currentDay > daysInMonth) {
      break;
    }
  }

  return calendar;
};

type CalendarGroup = Record<string, Calendar>;

export const generateOneYearFromCurrentMonth = (
  minDate?: string,
): CalendarGroup[] => {
  const currentDate: Dayjs = minDate ? dayjs(minDate) : dayjs(); // Get the current date
  let currentYear: number = currentDate.year(); // Extract the current year
  let currentMonth: number = currentDate.month() + 1; // Extract the current month (0-indexed, so we add 1)

  const allCalendars: Record<string, Calendar> = {}; // Object to store the calendars

  // Loop through the next 12 months
  for (let i = 0; i < 12; i++) {
    // Generate the calendar for the current month
    const calendar: Calendar = generateCalendar(currentYear, currentMonth);

    // Store the calendar with a key like "2024-10" for October 2024
    allCalendars[`${currentYear}-${String(currentMonth).padStart(2, "0")}-01`] =
      calendar;

    // Move to the next month
    currentMonth++;

    // If we go beyond December (month 12), increment the year and reset the month to January (1)
    if (currentMonth > 12) {
      currentMonth = 1;
      currentYear++;
    }
  }

  const arrayFromAllCalendars = Object.entries(allCalendars).map(
    ([key, value]) => ({ [key]: value }),
  );

  return arrayFromAllCalendars;
};

export const generateDayNamesForWeek = (): string[] => {
  const dayNames: string[] = [];
  const referenceDay: Dayjs = dayjs().day(0); // Start from Sunday (0 = Sunday in dayjs)

  // Loop through the week (0 to 6 for Sunday to Saturday)
  for (let i = 0; i < 7; i++) {
    const dayName: string = referenceDay.add(i, "day").format("ddd"); // 'ddd' gives abbreviated day name (e.g., 'Sun', 'Mon')
    dayNames.push(dayName);
  }

  return dayNames;
};

export const getRouteIndex = (
  tripType: string,
  routesDates: string[],
  newDate: string,
  routeIndex: number,
) => {
  if (tripType === "roundTrip") {
    const firstRouteDate = dayjs(routesDates[0]);
    return firstRouteDate &&
      (dayjs(newDate).isSame(firstRouteDate, "day") ||
        dayjs(newDate).isAfter(firstRouteDate, "day"))
      ? 1
      : 0;
  }
  return routeIndex;
};

// Utility to check if a day is disabled
export const isDayDisabled = ({
  day,
  minDate,
  maxDate,
}: {
  day: string;
  minDate: string;
  maxDate?: string;
}) => {
  const currentDay = dayjs(day);
  const startDate = dayjs(minDate);
  const endDate = maxDate ? dayjs(maxDate) : undefined;

  const isWithinAllowedRange = endDate
    ? currentDay.isBetween(startDate, endDate, "day", "[]")
    : currentDay.isSameOrAfter(startDate, "day");

  const isWithinOneYearRange = currentDay.isBetween(
    dayjs().startOf("month"),
    dayjs().add(1, "year").endOf("month"),
    "day",
    "[]",
  );

  return !(isWithinAllowedRange && isWithinOneYearRange);
};

// Utility to get dates between selected start and end dates
export const getDayStatus = (
  day: string,
  selectedDates: string[],
): {
  isSelected: boolean;
  isBetween: boolean;
  isStart: boolean;
  isEnd: boolean;
} => {
  const isSelected = selectedDates.includes(day);

  // Handle the case when there's only one selected date
  if (selectedDates.length === 1) {
    const singleDate = dayjs(selectedDates[0]);
    const isStart = dayjs(day).isSame(singleDate, "day");
    const isEnd = isStart; // Single date is both start and end
    return { isSelected, isBetween: false, isStart, isEnd };
  }

  // Handle the case when there are two selected dates
  if (selectedDates.length === 2) {
    const [start, end] = selectedDates.map(dayjs);

    const isStart = dayjs(day).isSame(start, "day");
    const isEnd = dayjs(day).isSame(end, "day");
    const isBetween = dayjs(day).isBetween(start, end, "day", "()");

    return { isSelected, isBetween, isStart, isEnd };
  }

  // Default case: no dates or more than two dates
  return { isSelected, isBetween: false, isStart: false, isEnd: false };
};

export const findCalendarIndexByDateKey = (
  dates: CalendarGroup[],
  date: string,
) => {
  let index = 0;
  const startOfMonth = dayjs(date).startOf("month").format("YYYY-MM-DD");
  index = dates.findIndex((obj) => Object.keys(obj).includes(startOfMonth));
  return index;
};
