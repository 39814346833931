import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/about-us/about-us.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/benefits/icons/best-price.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/benefits/icons/call-center.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/benefits/icons/free-cancellation.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/benefits/icons/tickets.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/blog/blog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/ferry-companies/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/header/bubble-title/bubble-title-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/header/map/pseudo-map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/latest-offers/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/manage-booking/bubble.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/manage-booking/cta.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/manage-booking/manage-booking.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/latest-offers/latest-offers.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/ferry-companies/ferry-companies.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/benefits/benefits.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/header/header.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/manage-booking/manage-my-booking.jpg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/icons/brand-mark.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/icons/explore-user.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/icons/paper-free.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/icons/pin-search.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/icons/sync.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/images/iPhones.png");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/images/mobile-screenshot.png");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/mobile-app/mobile-app.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/newsletter/newsletter.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/popular-routes/badge.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/popular-routes/ellipse.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/popular-routes/illustration.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/popular-routes/popular-route.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/popular-routes/popular-routes.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/support/mobile-support.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/support/open-chat-button.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/support/support.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/support/support.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/google-half-star.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/google-logo.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/google-stars.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/trustpilot-half.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/trustpilot-logo.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/icons/trustpilot-stars.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/title/title.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/trending-destinations/destinations.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/trending-destinations/trending-destinations.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/[lang]/ferry/sections/testimonials/testimonials.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/[lang]/ferry/sections/upsell/upsell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/chat/chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/search-mask/search-mask.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/images/app-store.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/images/google-play.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
