import { Route } from "./types";
import initialState from "./initialState";
import store from "@/store";
import { actions, selectors } from "@/store/routes";

export const handleOneWayTrip = (routes: Route[]): Route[] => {
  return [routes[0]];
};

export const handleRoundTrip = (routes: Route[]): Route[] => {
  const firstRoute = routes[0];
  const roundTripRoute = {
    ...firstRoute,
    departurePortId: firstRoute.arrivalPortId,
    arrivalPortId: firstRoute.departurePortId,
    date: routes[1]?.date || "",
  };
  return [firstRoute, roundTripRoute];
};

export const handleMultiTrip = (routes: Route[]): Route[] => {
  const isEmptyRoute = (route: Record<string, string>): boolean => {
    return Object.values(route).every((value) => value === "");
  };
  if (routes.length > 1 && isEmptyRoute(routes[1])) {
    routes.pop();
  }
  return routes;
};

export const isOutbound = (index: number, routes: Route[]) =>
  index < 0 || index > routes.length - 1;

export const updateRouteProperty = ({
  state,
  index,
  property,
  value,
}: {
  state: typeof initialState;
  index: number;
  property: keyof Route;
  value: string;
}) => {
  if (isOutbound(index, state.routes)) return;
  state.routes[index][property] = value;
};

export const resetArrivalIdAndDate = ({
  index,
  value,
  state,
}: {
  index: number;
  value: string;
  state: typeof initialState;
}) => {
  if (state.routes[index].departurePortId !== value) {
    updateRouteProperty({
      state,
      index,
      property: "date",
      value: "",
    });
    updateRouteProperty({
      state,
      index,
      property: "arrivalPortId",
      value: "",
    });
  }
};

export const removeFromRoutes = (routes: Route[], index: number) => {
  return [...routes.slice(0, index), ...routes.slice(index + 1)];
};

export const handlePortsSelection = ({ id }: { id: string }) => {
  const routes = selectors.getRoutes(store.getState());
  const tripType = selectors.getTripType(store.getState());

  const lastPortIndex = routes.length - 1;

  if (lastPortIndex < 0) {
    return;
  }

  const currentRoute = routes[lastPortIndex]; // Handling at least two routes for round-trip
  const { departurePortId, arrivalPortId } = currentRoute || {};

  if (tripType === "oneWay") {
    if (departurePortId === id) {
      store.dispatch(actions.resetRoutes());
      return;
    }

    if (arrivalPortId === id) {
      store.dispatch(actions.changeRouteDate({ index: 0, value: "" }));
      store.dispatch(actions.changeRouteArrival({ index: 0, value: "" }));
      return;
    }

    if (!departurePortId) {
      store.dispatch(actions.changeRouteDeparture({ index: 0, value: id }));
      return;
    }

    if (!arrivalPortId) {
      store.dispatch(actions.changeRouteArrival({ index: 0, value: id }));
    }
    return;
  }

  if (tripType === "roundTrip") {
    if (routes[0].departurePortId === id) {
      store.dispatch(actions.resetRoutes());
      return;
    }

    if (routes[0].arrivalPortId === id) {
      store.dispatch(actions.changeRouteArrival({ index: 0, value: "" }));
      store.dispatch(actions.changeRouteDate({ index: 0, value: "" }));
      store.dispatch(actions.changeRouteDeparture({ index: 1, value: "" }));
      store.dispatch(actions.changeRouteDate({ index: 1, value: "" }));
      return;
    }

    if (!routes[0].departurePortId) {
      store.dispatch(actions.changeRouteDeparture({ index: 0, value: id }));
      return;
    }

    if (!routes[0].arrivalPortId) {
      store.dispatch(actions.changeRouteArrival({ index: 0, value: id }));
    }

    return;
  }

  if (tripType === "multiTrip") {
    if (!routes[lastPortIndex].departurePortId) {
      store.dispatch(
        actions.changeRouteDeparture({ index: lastPortIndex, value: id }),
      );
      return;
    }

    if (!routes[lastPortIndex].arrivalPortId) {
      store.dispatch(
        actions.changeRouteArrival({ index: lastPortIndex, value: id }),
      );
    }
  }
};
