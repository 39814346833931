import { RootState } from "../types";
import { REDUCER_NAME } from "./constants";

const getDeparturePortLocationName = (state: RootState) =>
  state[REDUCER_NAME].departurePortLocationName;

const getOpenReturnField = (state: RootState) =>
  state[REDUCER_NAME].openReturnField;

const getOpenDateField = (state: RootState) =>
  state[REDUCER_NAME].openDateField;

const selectors = {
  getDeparturePortLocationName,
  getOpenReturnField,
  getOpenDateField,
};

export default selectors;
