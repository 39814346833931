import React from "react";

import FollowUs from "./follow-us";
import { NavMenuItem } from "./types";
import { Locale } from "@/i18n-config";

type Props = {
  lang: Locale;
  data: NavMenuItem;
};

const FindUs: React.FC<Props> = ({ data, lang }) => {
  return (
    <div className="lg:col-span-2">
      <div>
        {data.title && (
          <div className="mb-4 text-lg font-bold text-muted">{data.title}</div>
        )}
        {data.children && data.children?.length > 0 && (
          <ul>
            {data.children.map((value, index) => (
              <li key={index}>{value.title}</li>
            ))}
          </ul>
        )}
      </div>
      <FollowUs lang={lang} />
    </div>
  );
};

export default FindUs;
