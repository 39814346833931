export type translations = {
  [key: string]: {
    title: string;
    subtitle: string;
    cta: string;
    pageTitle?: string;
  };
};

export const translationsNotFound: translations = {
  en: {
    title: "Page not found",
    subtitle: "We couldn't find the page you were looking for.",
    cta: "Back to Homepage",
    pageTitle:
      "Oops! You've hit rough waters. Let's get you back on course... | Ferryscanner",
  },
  it: {
    title: "Pagina non trovata",
    subtitle: "Non siamo riusciti a trovare la pagina che cercavi.",
    cta: "Torna alla Home",
    pageTitle:
      "Ops! Hai incontrato acque agitate. Torniamo subito sulla giusta rotta... | Ferryscanner",
  },
  el: {
    title: "Η σελίδα δε βρέθηκε",
    subtitle: "Δεν βρήκαμε τη σελίδα που έψαχνες.",
    cta: "Επιστροφή στην Αρχική Σελίδα",
    pageTitle:
      "Ωχ! Φαίνεται πως έπεσες σε φουρτούνες. Πάμε να σε βάλουμε ξανά στη σωστή πορεία... | Ferryscanner",
  },
  hr: {
    title: "Stranica nije pronađena",
    subtitle: "Nismo mogli pronaći stranicu koju tražiš.",
    cta: "Povratak na početnu stranicu",
    pageTitle:
      "Ups! Naišao si na nemirne vode. Vratimo te na pravi kurs... | Ferryscanner",
  },
  es: {
    title: "Página no encontrada",
    subtitle: "No pudimos encontrar la página que buscabas.",
    cta: "Vuelve a la Página Principal",
    pageTitle:
      "¡Ups! Has encontrado aguas turbulentas. Volvamos a encarrilarte... | Ferryscanner",
  },
  pt: {
    title: "Página não encontrada",
    subtitle: "Não conseguimos encontrar a página que procuravas.",
    cta: "Voltar à Página Inicial",
    pageTitle:
      "Ops! Você encontrou águas turbulentas. Vamos colocá-lo de volta no rumo certo... | Ferryscanner",
  },
  fr: {
    title: "Page introuvable",
    subtitle: "Nous n'avons pas pu trouver la page que vous cherchiez.",
    cta: "Retour à la page d'accueil",
    pageTitle:
      "Oups ! Tu as rencontré des eaux agitées. Remettons-toi sur la bonne voie... | Ferryscanner",
  },
  pl: {
    title: "Strona nie znaleziona",
    subtitle: "Nie znaleźliśmy strony, której szukałeś.",
    cta: "Wróć na Stronę Główną",
    pageTitle:
      "Ups! Wpadłeś na wzburzone wody. Przywróćmy cię na właściwy kurs... | Ferryscanner",
  },
  de: {
    title: "Seite nicht gefunden",
    subtitle: "Wir konnten die Seite, die du suchst, nicht finden.",
    cta: "Zurück zur Startseite",
    pageTitle:
      "Hoppla! Du bist in raue See geraten. Lass uns dich wieder auf Kurs bringen... | Ferryscanner",
  },
  da: {
    title: "Siden blev ikke fundet",
    subtitle: "Vi kunne ikke finde siden, du ledte efter.",
    cta: "Tilbage til forsiden",
    pageTitle:
      "Ups! Du er stødt på uroligt farvand. Lad os få dig tilbage på rette kurs... | Ferryscanner",
  },
  no: {
    title: "Siden finnes ikke",
    subtitle: "Vi fant ikke siden du lette etter.",
    cta: "Tilbake til Hjemmesiden",
    pageTitle:
      "Oi! Du har truffet på urolig sjø. La oss få deg tilbake på rett kurs... | Ferryscanner",
  },
  tr: {
    title: "Sayfa bulunamadı",
    subtitle: "Aradığın sayfayı bulamadık.",
    cta: "Ana Sayfaya Dönüş",
    pageTitle:
      "Hay aksi! Dalgalı sulara girdin. Seni tekrar rotana sokalım... | Ferryscanner",
  },
  nl: {
    title: "Pagina niet gevonden",
    subtitle: "De pagina die je zocht, konden we niet vinden.",
    cta: "Terug naar de Homepage",
    pageTitle:
      "Oeps! Je bent in woelig water terechtgekomen. Laten we je weer op koers brengen... | Ferryscanner",
  },
  fi: {
    title: "Sivua ei löytynyt",
    subtitle: "Emme löytäneet etsimääsi sivua.",
    cta: "Takaisin etusivulle",
    pageTitle:
      "Hups! Olet joutunut myrskyisille vesille. Ohjataan sinut takaisin oikealle kurssille... | Ferryscanner",
  },
  sv: {
    title: "Sidan hittas inte",
    subtitle: "Vi kunde inte hitta sidan du letade efter.",
    cta: "Tillbaka till hemsidan",
    pageTitle:
      "Hoppsan! Du har stött på stormigt vatten. Låt oss få dig tillbaka på rätt kurs... | Ferryscanner",
  },
  sq: {
    title: "Faqja nuk u gjet",
    subtitle: "Nuk gjetëm dot faqen që kërkoje.",
    cta: "Kthehu te Faqja Kryesore",
    pageTitle:
      "Ups! Ke hasur ujëra të trazuara. Le të të kthejmë në rrugën e duhur... | Ferryscanner",
  },
  et: {
    title: "Lehte ei leitud",
    subtitle: "Kahjuks ei leidnud me lehte, mida otsisid.",
    cta: "Tagasi avalehele",
    pageTitle:
      "Ups! Oled sattunud tormistele vetele. Toome sind tagasi õigele kursile... | Ferryscanner",
  },
  ja: {
    title: "ページが見つかりませんでした。",
    subtitle: "お探しのページが見つかりませんでした。",
    cta: "ホームページに戻ろう",
    pageTitle:
      "おっと！あなたは荒波にぶつかってしまいました。コースに戻りましょう... | Ferryscanner",
  },
  ko: {
    title: "페이지를 찾을 수 없어요",
    subtitle: "찾으시는 페이지를 찾을 수 없었어요.",
    cta: "홈페이지로 돌아가기",
    pageTitle:
      "이런! 당신이 험난한 바다에 빠졌네요. 다시 정상 궤도로 돌아가 봅시다... | Ferryscanner",
  },
  zh: {
    title:
      "页面未找到，但别担心！每一次探索都是新的机会。继续你的旅程，精彩就在前方。",
    subtitle: "我们找不到你要的页面",
    cta: "返回主页",
    pageTitle:
      "哎呀！你遇到了波涛汹涌的海域。让我们带你重回正轨... | Ferryscanner",
  },
};

export const translationsError: translations = {
  en: {
    title: "Oops! Something Went Wrong",
    subtitle:
      "It looks like the server doesn't support the HTTP version used in your request.",
    cta: "Back to Homepage",
  },
  it: {
    title: "Ops! Qualcosa è andato storto",
    subtitle:
      "Sembra che il server non supporti la versione HTTP usata nella tua richiesta.",
    cta: "Torna alla Home",
  },
  el: {
    title: "Ωχ! Κάτι πήγε στραβά",
    subtitle:
      "Φαίνεται πως ο διακομιστής δε μπορεί να υποστηρίξει την έκδοση HTTP που χρησιμοποιείς στο αίτημά σου.",
    cta: "Επιστροφή στην Αρχική Σελίδα",
  },
  hr: {
    title: "Ups! Nešto je pošlo po zlu",
    subtitle:
      "Čini se da server ne podržava verziju HTTP-a koju koristiš u svom zahtjevu.",
    cta: "Povratak na početnu stranicu",
  },
  es: {
    title: "¡Uy! Algo salió mal",
    subtitle:
      "Parece que el servidor no admite la versión de HTTP que usaste en tu solicitud.",
    cta: "Vuelve a la Página Principal",
  },
  pt: {
    title: "Ops! Algo correu mal",
    subtitle:
      "Parece que o servidor não suporta a versão HTTP usada no teu pedido.",
    cta: "Voltar à Página Inicial",
  },
  fr: {
    title: "Oups ! Un petit problème est survenu.",
    subtitle:
      "Il semble que le serveur ne prenne pas en charge la version HTTP utilisée dans votre requête.",
    cta: "Retour à la page d'accueil",
  },
  pl: {
    title: "Ups! Coś poszło nie tak",
    subtitle:
      "Wygląda na to, że serwer nie obsługuje wersji HTTP użytej w Twoim żądaniu.",
    cta: "Wróć na Stronę Główną",
  },
  de: {
    title: "Hoppla! Da ist etwas schiefgelaufen.",
    subtitle:
      "Es sieht so aus, als ob der Server die HTTP-Version in deiner Anfrage nicht unterstützt.",
    cta: "Zurück zur Startseite",
  },
  da: {
    title: "Ups! Noget gik galt",
    subtitle:
      "Det ser ud til, at serveren ikke understøtter den HTTP-version, du bruger i din anmodning.",
    cta: "Tilbage til forsiden",
  },
  no: {
    title: "Oi! Noe gikk galt",
    subtitle:
      "Det ser ut som serveren ikke støtter HTTP-versjonen du brukte i forespørselen din.",
    cta: "Tilbake til Hjemmesiden",
  },
  tr: {
    title: "Eyvah! Bir Hata Oluştu",
    subtitle:
      "Sunucunun isteğinizde kullanılan HTTP sürümünü desteklemediği anlaşılıyor.",
    cta: "Ana Sayfaya Dönüş",
  },
  nl: {
    title: "Oeps! Er ging iets mis",
    subtitle:
      "Het lijkt erop dat de server de HTTP-versie in je verzoek niet ondersteunt.",
    cta: "Terug naar de Homepage",
  },
  fi: {
    title: "Hups! Jotain meni pieleen",
    subtitle: "Näyttää siltä, että palvelin ei tue pyyntösi HTTP-versiota.",
    cta: "Takaisin etusivulle",
  },
  sv: {
    title: "Oj! Något gick fel",
    subtitle:
      "Det verkar som att servern inte stöder den HTTP-version du använde i din begäran.",
    cta: "Tillbaka till hemsidan",
  },
  sq: {
    title: "Ups! Diçka nuk shkoi si duhet",
    subtitle:
      "Duket se serveri nuk mbështet versionin e HTTP që ke përdorur në kërkesën tënde.",
    cta: "Kthehu te Faqja Kryesore",
  },
  et: {
    title: "Ups! Midagi läks viltu",
    subtitle:
      "Tundub, et server ei toeta sinu päringus kasutatud HTTP versiooni.",
    cta: "Tagasi avalehele",
  },
  ja: {
    title: "おっと！何か問題が発生しました。",
    subtitle:
      "サーバーがあなたのリクエストに使われたHTTPバージョンをサポートしていないみたいです。",
    cta: "ホームページに戻ろう",
  },
  ko: {
    title: "이런! 문제가 발생했네요",
    subtitle: "서버가 요청하신 HTTP 버전을 지원하지 않는 것 같아요.",
    cta: "홈페이지로 돌아가기",
  },
  zh: {
    title: "糟糕，你遇到问题了！",
    subtitle: "看起来服务器不支持你请求中使用的HTTP版本。",
    cta: "返回主页",
  },
};
