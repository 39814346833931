import Image from "next/image";
import GoogleStars from "./icons/google-stars.svg";
import TrustpilotStars from "./icons/trustpilot-stars.svg";
import googleHalfStar from "./icons/google-half-star.svg";
import trustPilotHalfStar from "./icons/trustpilot-half.svg";
interface RenderStarsProps {
  stars: number;
  isGoogle: boolean;
}

const RenderStars: React.FC<RenderStarsProps> = ({ stars, isGoogle }) => {
  const fullStar = isGoogle ? GoogleStars : TrustpilotStars;
  const halfStar = isGoogle ? googleHalfStar : trustPilotHalfStar;

  const icon = stars < 5 ? halfStar : fullStar;

  return <Image alt={`${stars} rating`} src={icon} />;
};

export default RenderStars;
