"use client";

import React, { useState } from "react";
import PlusIcon from "@/icons/plus";
import clsx from "clsx";

import { NavMenuItem } from "./types";
import FindUs from "./find-us";

type Props = {
  lang: string;
  data: NavMenuItem[];
};

const externalKeywords = ["recruitee", "blog"];

const Item = ({ data }: { data: NavMenuItem }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <div>
      {data.title && (
        <div
          className="flex cursor-pointer items-center justify-between text-lg font-bold text-muted lg:cursor-default"
          onClick={toggleOpen}
        >
          {data.title}
          <PlusIcon className="block lg:hidden" />
        </div>
      )}
      <ul
        className={clsx("mt-4 flex-col gap-2", {
          "hidden lg:flex": !open && data.title,
          flex: open || !data.title,
        })}
      >
        {data.children?.map((item, index) => {
          if (item.url === "#") {
            return <li key={index}>{item.title}</li>;
          }
          const isExternal = externalKeywords.some((keyword) =>
            item.url.includes(keyword),
          );
          return (
            <li key={index}>
              <a
                href={item.url}
                className="text-default"
                target={isExternal ? "_blank" : "_self"}
              >
                {item.title.replace(/&amp;/g, "&")}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Menu: React.FC<Props> = ({ data, lang }) => {
  return data.map((item, index) => {
    if (index === 0) {
      return <FindUs key={item.id} lang={lang} data={item} />;
    }
    return <Item key={item.id} data={item} />;
  });
};

export default Menu;
