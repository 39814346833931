"use client";

import React, { useState } from "react";
import dynamic from "next/dynamic";
import { Translations } from "@/global-types/translations";

import Actions from "./actions";
import NavbarToggler from "./navbar-toggler";

import { LINKS } from "./constants";
import { openBeacon } from "@/utils/open-chat";
import useCurrencies from "@/components/navbar/useCurrencies";

const Dropdown = dynamic(() => import("@/components/dropdown"), { ssr: false });

interface Props {
  lang: string;
  translations: Translations;
}

const Mobile = ({
  lang,

  translations,
}: Props) => {
  const { defaultCurrency, availableCurrencies } = useCurrencies();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!defaultCurrency || !availableCurrencies.length) {
    return;
  }

  return (
    <>
      <NavbarToggler onClick={handleClick} open={open} />
      <Dropdown
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="w-full"
      >
        <div className="mb-1 flex flex-col px-1">
          {LINKS.map((link) => (
            <a
              className="btn btn-text-primary justify-start"
              key={link.label}
              href={`/${lang}${link.href}`}
            >
              {translations?.[link.term] || link.label}
            </a>
          ))}
          <Actions
            translations={translations}
            lang={lang}
            availableCurrencies={availableCurrencies}
            defaultCurrency={defaultCurrency}
          />
          <button
            className="btn btn-text-primary justify-start"
            onClick={() => openBeacon()}
          >
            {translations.lets_chat || "Let's chat"}
          </button>
        </div>
      </Dropdown>
    </>
  );
};

export default Mobile;
