import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash/throttle";
import reducers from "./reducers";
import { saveState } from "./persistent-storage";
import { persistent } from "./persistent";
import { RootState } from "@/store/types";

const store = configureStore({
  reducer: reducers,
});

// Subscribe to store and save state to localStorage
store.subscribe(
  throttle(() => {
    saveState(persistent(store.getState()) as RootState);
  }, 1000), // Throttle the saving process to once every second
);

export default store;
