import React from "react";
import styles from "./about-us-desktop.module.css";

const Circle = () => {
  return (
    <svg
      width={252}
      height={252}
      viewBox="0 0 252 252"
      fill="none"
      className={styles.circle}
    >
      <path
        d="M247.791 157.106C238.382 194.112 212.634 224.821 177.836 240.538C143.037 256.255 102.974 255.271 68.9893 237.863C35.0043 220.456 10.7954 188.52 3.21563 151.097C-4.36413 113.673 5.51022 74.834 30.0435 45.5745C54.5767 16.315 91.0987 -0.180517 129.271 0.757369C167.443 1.69525 203.111 19.9645 226.178 50.3935C249.244 80.8225 257.199 120.099 247.791 157.106Z"
        fill="url(#paint0_linear_1701_16009)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1701_16009"
          x1={232.903}
          y1={215.666}
          x2={19.4755}
          y2={36.7136}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE5BF" />
          <stop offset={1} stopColor="white" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Circle;
