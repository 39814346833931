import React from "react";
import clsx from "clsx";
import styles from "./label.module.css";

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  size: "md" | "lg";
  color: "default" | "error" | "success";
}

const Label = (props: LabelProps) => {
  const { size, color, ...other } = props;

  const sizeClass = styles[`size_${size}`];
  const colorClass = styles[`color_${color}`];
  const classes = clsx(styles.root, colorClass, sizeClass);

  return <label className={classes} {...other} />;
};

export default Label;
