"use client";

import React from "react";
import Image from "next/image";
import { chunk } from "lodash";
import useEmblaCarousel from "embla-carousel-react";
import clsx from "clsx";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";
import { DotButton, useDotButton } from "@/components/embla-dot-buttons";
import { CompaniesItem } from "../../types";

import styles from "./ferry-companies.module.css";

type Props = {
  slides: CompaniesItem[];
};

const Carousel: React.FC<Props> = ({ slides }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
  });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const chunkSlides = chunk(
    slides.filter((item) => item.company_image),
    16,
  );

  return (
    <>
      <div ref={emblaRef} className="relative overflow-hidden">
        <div className="flex">
          {chunkSlides.map((slide, slideIndex) => (
            <div key={slideIndex} className={styles.slide}>
              <div className={styles.slide_item}>
                {slide.map((company, index) => (
                  <a
                    key={index}
                    href={company.company_link}
                    className={styles.slide_item_link}
                    title={company.company_title}
                  >
                    <Image
                      src={company.company_image as string}
                      alt={company.company_title}
                      fill
                      sizes="(max-width: 1279px) 72px, 100px"
                      className="object-contain"
                    />
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <PrevButton
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
          className={styles.carousel_button}
          style={{ left: 56 }}
        />
        <NextButton
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
          className={styles.carousel_button}
          style={{ right: 56 }}
        />
      </div>
      <div className="my-10 flex justify-center">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            className="p-1.5"
            onClick={() => onDotButtonClick(index)}
          >
            <span
              className={clsx("block h-3 w-3 rounded-full bg-black", {
                "bg-opacity-20": index !== selectedIndex,
              })}
            />
            <span className="sr-only">{`Scroll to slide ${index + 1}`}</span>
          </DotButton>
        ))}
      </div>
    </>
  );
};

export default Carousel;
