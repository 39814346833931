"use client";

import React from "react";

type Props = {
  link: string;
  label: string;
};

const Cta = ({ link, label }: Props) => {
  return (
    <a href={link} className="btn btn-outline-accent md:self-start">
      {label}
    </a>
  );
};

export default Cta;
