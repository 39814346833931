import React from "react";
import { Translations } from "@/global-types/translations";
import CloseIcon from "@/icons/close";

import styles from "../content.module.css";

interface Props {
  onClose: () => void;
  translations: Translations | undefined;
}

const Header = ({ translations, onClose }: Props) => {
  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>
          {translations?.regional_settings || "Settings"}
        </p>
        <button onClick={onClose} aria-label="Close">
          <CloseIcon />
        </button>
      </div>
      <div className={styles.divider} />
    </>
  );
};

export default Header;
