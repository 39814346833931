import React, { forwardRef } from "react";
import CloseIcon from "@/icons/close";
import MenuIcon from "@/icons/menu";

interface Props {
  open?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const NavbarToggler = forwardRef<HTMLButtonElement, Props>(
  ({ open, onClick }, ref) => {
    const Icon = open ? CloseIcon : MenuIcon;
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className="-mr-2 inline-flex h-10 w-10 items-center justify-center p-2 text-sm text-primary"
      >
        <Icon className="h-8 w-8" />
        <span className="sr-only">Open main menu</span>
      </button>
    );
  },
);

NavbarToggler.displayName = "NavbarToggler";

export default NavbarToggler;
