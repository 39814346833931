import React from "react";
import { generateDayNamesForWeek } from "./utils";

import styles from "./calendar.module.css";

const WeekDays = () => {
  const dayNamesForWeek = generateDayNamesForWeek();
  return (
    <div className={styles.week_grid}>
      {dayNamesForWeek.map((day, index) => (
        <div key={index} className="text-center text-sm font-bold">
          {day}
        </div>
      ))}
    </div>
  );
};

export default WeekDays;
