import * as React from "react";
const ArrowDown = () => (
  <svg fill="none" viewBox="0 0 8 8" width={8} height={8}>
    <path
      fill="#008BFF"
      d="M6.854 2.204 3.992 5.066 1.13 2.204a.667.667 0 0 0-.943.942L3.52 6.48a.667.667 0 0 0 .943 0l3.333-3.334a.667.667 0 1 0-.942-.942Z"
    />
  </svg>
);
export default ArrowDown;
