import { OPTIONS } from "@/data/constants";
import { User } from "@/global-types/user";

import * as C from "./constants";

export const getCurrencyData = async (): Promise<{
  availableCurrencies: string[];
  defaultCurrency: string;
}> => {
  const response = await fetch(C.CURRENCY_URL, { ...OPTIONS });
  const data = await response.json();

  return {
    availableCurrencies: data?.results?.data?.availableCurrencies,
    defaultCurrency: data?.results?.data?.defaultCurrency,
  };
};

export const getUser = async ({
  uuid,
  sessionToken,
}: {
  uuid: string;
  sessionToken: string;
}): Promise<User | null> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/users/${uuid}?basicData=true&tp24Id=${sessionToken}`,
    { ...OPTIONS },
  );
  const data = await response.json();
  const accounts = data?.response?.result?.accounts;

  if (!accounts || !accounts[0]) {
    return null;
  }

  return accounts[0];
};
