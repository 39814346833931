import React from "react";
import Image from "next/image";

import { AboutUs as AboutUsType } from "../../types";

import MeetingRoom from "./assets/ferryscanner-meeting-room.png";
import LobyEntrance from "./assets/ferryscanner-loby-entrance.png";
import OpenOfficeOne from "./assets/ferryscanner-open-office-one.png";
import LobyWallArt from "./assets/ferryscanner-loby-wall-art.png";
import WaitingRoom from "./assets/ferryscanner-waiting-room.png";
import Kitchen from "./assets/ferryscanner-kitchen.png";
import OpenOfficeTwo from "./assets/ferryscanner-open-office-two.png";

import Title from "../title";

import styles from "./about-us-mobile.module.css";

const Mobile = ({ data }: { data: AboutUsType }) => {
  return (
    <div className={styles.wrapper_grid}>
      <div className={styles.side_grid}>
        <div className={styles.image_wide}>
          <Image
            src={MeetingRoom}
            fill
            alt={data.about_us_image_alt_text[0].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
        <div className="relative">
          <Image
            src={OpenOfficeOne}
            fill
            alt={data.about_us_image_alt_text[4].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.main_grid}>
        <div className={styles.content}>
          <Title>{data.about_us_title}</Title>
          <p>{data.about_us_description}</p>
          <a
            href={data.about_us_call_to_action_link}
            className="btn btn-accent"
          >
            {data.about_us_call_to_action_label}
          </a>
        </div>
        <div className="relative">
          <Image
            src={LobyWallArt}
            fill
            alt={data.about_us_image_alt_text[1].image_alt_text}
            sizes="50vw"
            className={styles.image}
            style={{ objectPosition: "left" }}
          />
        </div>
      </div>
      <div className={styles.side_grid}>
        <div className={styles.image_wide}>
          <Image
            src={LobyEntrance}
            fill
            alt={data.about_us_image_alt_text[3].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
        <div className="relative">
          <Image
            src={WaitingRoom}
            fill
            alt={data.about_us_image_alt_text[5].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.side_grid}>
        <div className="relative">
          <Image
            src={Kitchen}
            fill
            alt={data.about_us_image_alt_text[2].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
        <div className={styles.image_wide}>
          <Image
            src={OpenOfficeTwo}
            fill
            alt={data.about_us_image_alt_text[6].image_alt_text}
            sizes="50vw"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default Mobile;
