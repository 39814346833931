"use client";

import React from "react";
import styles from "./popular-routes.module.css";

const Ellipse = () => {
  return (
    <div className={styles.ellipse}>
      <svg width={484} height={485} viewBox="0 0 484 485" fill="none">
        <circle
          cx={242.186}
          cy={242.45}
          r={241.624}
          transform="rotate(-46.9814 242.186 242.45)"
          fill="#C4E4FF"
        />
      </svg>
    </div>
  );
};
export default Ellipse;
