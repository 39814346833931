"use client";
import React from "react";
import { Port } from "@/global-types/ports";
import dynamic from "next/dynamic";
import Image from "next/image";
import mapImage from "./img/map.webp";

import styles from "./map.module.css";

const Map = dynamic(() => import("./map"), {
  ssr: false,
  loading: () => (
    <div className={styles.pseudo_map}>
      <Image
        src={mapImage}
        fill
        alt="map"
        className={styles.pseudo_map_image}
        priority
        quality={100}
      />
    </div>
  ),
});

interface Props {
  portsData: Port[];
  lang: string;
}

const PseudoMap = ({ portsData, lang }: Props) => {
  return <Map portsData={portsData} lang={lang} />;
};

export default PseudoMap;
