import React from "react";

const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    width={20}
    height={20}
    stroke="currentColor"
    strokeWidth={1}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
    {...props}
  >
    <path d="M2.5 5.5H13.5" />
    <path d="M15.5 2.5H0.5V14.5H15.5V2.5Z" />
    <path d="M4.5 0.5V2.5" />
    <path d="M11.5 0.5V2.5" />
  </svg>
);
export default CalendarIcon;
