"use client";

import Image from "next/image";
import dayjs from "dayjs";

import { Blog as BlogType } from "../../types";
import Title from "../title";

import styles from "./blog.module.css";
import { getPostLink } from "./utils";
import useEmblaCarousel from "embla-carousel-react";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";

interface Props {
  lang: string;
  data: BlogType;
}

const Blog = ({ data, lang }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
  });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  if (!data.blog_items.length) {
    return null;
  }

  return (
    <section className={styles.root}>
      <Title className="mb-10 text-center">{data.blog_title}</Title>
      <div className="overflow-hidden">
        <div className="container">
          <div className="mb-10 w-full" ref={emblaRef}>
            <div className="flex">
              {data.blog_items.map((post, index) => {
                const postLink = getPostLink(post.post_link, lang);
                return (
                  <div className={styles.slide} key={index}>
                    <div key={index} className={styles.card}>
                      <div className={styles.card_media}>
                        <Image
                          fill
                          sizes="(max-width: 640px) 80vw, (max-width: 720px) 33vw, 25vw"
                          src={post.post_image}
                          alt={post.post_title}
                        />
                      </div>
                      <div className={styles.card_content}>
                        <h3 className={styles.card_title}>
                          <a href={postLink} target="_blank">
                            {post.post_title}
                          </a>
                        </h3>
                        <p className={styles.card_date}>
                          {dayjs(post.post_date).format("DD MMMM YY")}
                        </p>
                        <p className={styles.card_text}>{post.post_excerpt}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.arrow_buttons}>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
              className={styles.carousel_button}
              style={{ left: 0 }}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
              className={styles.carousel_button}
              style={{ right: 0 }}
            />
          </div>
          <div className={styles.actions}>
            <a
              className="btn btn-accent"
              href={data.blog_call_to_action_link}
              target="_blank"
            >
              {data.blog_call_to_action_label}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
