import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";

const useSelectedDates = (routeIndex: number): string[] => {
  const routes = useSelector(selectors.getRoutes);
  const isMultitrip = useSelector(selectors.getIsMultitrip);

  const selectedDate = routes[routeIndex]?.date;
  const routesDates = routes.map((route) => route.date);

  // Return appropriate selected dates based on whether it's a multi-trip
  return isMultitrip ? [selectedDate] : routesDates;
};

export default useSelectedDates;
