"use client";

import { useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { beaconIsEnabled, openBeacon } from "@/utils/open-chat";

interface Props {
  isMobile: boolean;
}

const Chat = ({ isMobile }: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (isMobile) return;

    const interval = setInterval(() => {
      if (window.dataLayer) {
        if (!beaconIsEnabled()) {
          window.dataLayer.push({ event: "loadBeacon" });
        }
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isMobile]);

  useEffect(() => {
    const openChat = searchParams.get("openChat");
    if (openChat) {
      openBeacon();

      const params = new URLSearchParams(searchParams.toString());
      params.delete("openChat");
      router.replace(pathname + "?" + params.toString(), { scroll: false });
    }
  }, [searchParams, pathname, router]);

  return null;
};

export default Chat;
