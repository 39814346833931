import { createSelector } from "reselect";
import { RootState } from "../types";
import { REDUCER_NAME } from "./constants";

const getRoutes = (state: RootState) => state[REDUCER_NAME].routes;
const getTripType = (state: RootState) => state[REDUCER_NAME].tripType;

const getIsMultitrip = (state: RootState) => {
  const tripType = getTripType(state);
  return tripType === "multiTrip";
};

export const getSelectedPorts = createSelector([getRoutes], (routes) => {
  return routes
    .map(({ departurePortId, arrivalPortId }) => [
      departurePortId,
      arrivalPortId,
    ])
    .flat()
    .filter(Boolean);
});

const selectors = {
  getRoutes,
  getTripType,
  getIsMultitrip,
  getSelectedPorts,
};

export default selectors;
