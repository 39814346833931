import React, { Fragment } from "react";
import dayjs from "dayjs";

import { Translations } from "@/global-types/translations";

import WeekDays from "./week-days";
import Day from "./day";

import { Calendar } from "./types";

import styles from "./calendar.module.css";

interface Props {
  isMobile?: boolean;
  minDate: string;
  maxDate?: string;
  routeIndex: number;
  month: Record<string, Calendar>;
  onClose?: () => void;
  translations: Translations | undefined;
}

const Month = ({
  isMobile,
  month,
  minDate,
  maxDate,
  routeIndex,
  onClose,
  translations,
}: Props) =>
  Object.entries(month).map(([monthKey, weeks]) => (
    <div key={monthKey} className={styles.month_wrapper}>
      <h2 className="text-center text-lg">
        {dayjs(monthKey).format("MMMM YYYY")}
      </h2>
      <WeekDays />
      <div className={styles.calendar_grid}>
        {weeks.map((week, weekIndex) => (
          <Fragment key={weekIndex}>
            {week.map((day, dayIndex) => (
              <Day
                key={dayIndex}
                day={day}
                isMobile={isMobile}
                minDate={minDate}
                maxDate={maxDate}
                routeIndex={routeIndex}
                onClose={onClose}
                translations={translations}
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  ));

export default Month;
