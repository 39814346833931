import { Port } from "@/global-types/ports";

export const filterPortsByCountry = (ports: Port[], country: string | null) => {
  return country ? ports.filter((port) => port.countryName === country) : ports;
};

export const sortPortsByRank = (ports: Port[]) => {
  return ports.sort((a, b) => {
    const rankA = a.rank ? parseInt(a.rank) : Infinity;
    const rankB = b.rank ? parseInt(b.rank) : Infinity;
    return rankA - rankB;
  });
};
