import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@/store/routes";

import styles from "./multitrip.module.css";
import { Translations } from "@/global-types/translations";

type Props = {
  translations: Translations | undefined;
};

const AddRoute = ({ translations }: Props) => {
  const dispatch = useDispatch();
  const routes = useSelector(selectors.getRoutes);
  const disabled =
    routes.length === 10 ||
    routes.some(
      (route) => !route.arrivalPortId || !route.departurePortId || !route.date,
    );
  return (
    <button
      className={clsx("btn btn-outline-primary", styles.add_route_button)}
      onClick={() => dispatch(actions.addRoute())}
      disabled={disabled}
    >
      {translations?.add_one_more_trip || "Add Another Trip"}
    </button>
  );
};

export default AddRoute;
