import React from "react";

const Currency = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14.5 5h-13a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z" />
    <path d="M8 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM2.5 3h11M4.5 1h7" />
  </svg>
);
export default Currency;
