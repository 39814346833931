"use client";

import { Port } from "@/global-types/ports";

import ListItem from "../list-item";

import { filterPortsByCountry, sortPortsByRank } from "./utils";
import { Translations } from "@/global-types/translations";

interface Props {
  portsData: Port[];
  routeType: "departure" | "arrival";
  routeIndex: number;
  onSuggestionClick: (suggestion: string) => void;
  countryFilter: string;
  translations: Translations | undefined;
}

const PopularPorts = ({
  portsData,
  routeType,
  routeIndex,
  onSuggestionClick,
  countryFilter,
  translations,
}: Props) => {
  const filteredAndSortedPorts = sortPortsByRank(
    filterPortsByCountry(portsData, countryFilter),
  ).slice(0, 20);

  return (
    <ul className="flex-1 overflow-y-auto">
      <li className="sticky top-0 bg-white px-4 py-2">
        <b>{translations?.most_popular || "Most Popular"}</b>
      </li>
      {filteredAndSortedPorts.map((port) => (
        <ListItem
          key={port.ferryOwnLocationID}
          port={port}
          routeType={routeType}
          routeIndex={routeIndex}
          onSuggestionClick={onSuggestionClick}
        />
      ))}
    </ul>
  );
};

export default PopularPorts;
