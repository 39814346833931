import { Locale } from "@/i18n-config";

export const companyName = "ferryscanner.com";

export const SOCIAL_MEDIA_LINKS_TITLE: Record<
  Locale,
  {
    facebook: string;
    instagram: string;
    tiktok: string;
    linkedin: string;
    youtube: string;
    threads: string;
  }
> = {
  en: {
    facebook: "Follow Ferryscanner on Facebook",
    instagram: "Follow Ferryscanner on Instagram",
    tiktok: "Follow Ferryscanner on TikTok",
    linkedin: "Follow Ferryscanner on LinkedIn",
    youtube: "Follow Ferryscanner on YouTube",
    threads: "Follow Ferryscanner on Threads",
  },
  it: {
    facebook: "Segui Ferryscanner su Facebook",
    instagram: "Segui Ferryscanner su Instagram",
    tiktok: "Segui Ferryscanner su TikTok",
    linkedin: "Segui Ferrysscanner su LinkedIn",
    youtube: "Segui Ferryscanner su YouTube",
    threads: "Segui Ferryscanner su Threads",
  },
  el: {
    facebook: "Ακολούθησε τη Ferryscanner στο Facebook",
    instagram: "Ακολούθησε τη Ferryscanner στο Instagram",
    tiktok: "Ακολούθησε τη Ferryscanner στο TikTok",
    linkedin: "Ακολούθησε τη Ferryscanner στο LinkedIn",
    youtube: "Ακολούθησε τη Ferryscanner στο YouTube",
    threads: "Ακολούθησε τη Ferryscanner στο Threads",
  },
  hr: {
    facebook: "Prati Ferryscanner na Facebooku",
    instagram: "Prati Ferryscanner na Instagramu",
    tiktok: "Prati Ferryscanner na TikToku",
    linkedin: "Prati Ferryscanner na LinkedInu",
    youtube: "Prati Ferryscanner na YouTubeu",
    threads: "Prati Ferryscanner na Threads",
  },
  es: {
    facebook: "Síguenos en Facebook",
    instagram: "Síguenos en Instagram",
    tiktok: "Síguenos en TikTok",
    linkedin: "Síguenos en LinkedIn",
    youtube: "Síguenos en YouTube",
    threads: "Síguenos en Threads",
  },
  pt: {
    facebook: "Siga a Ferryscanner no Facebook",
    instagram: "Siga a Ferryscanner no Instagram",
    tiktok: "Siga a Ferryscanner no TikTok",
    linkedin: "Siga a Ferryscanner no LinkedIn",
    youtube: "Siga a Ferryscanner no YouTube",
    threads: "Siga a Ferryscanner no Threads",
  },
  fr: {
    facebook: "Suis Ferryscanner sur Facebook",
    instagram: "Suis Ferryscanner sur Instagram",
    tiktok: "Suis Ferryscanner sur TikTok",
    linkedin: "Suis Ferryscanner sur LinkedIn",
    youtube: "Suis Ferryscanner sur YouTube",
    threads: "Suis Ferryscanner sur Threads",
  },
  pl: {
    facebook: "Śledź Ferryscanner na Facebooku",
    instagram: "Śledź Ferryscanner na Instagramie",
    tiktok: "Śledź Ferryscanner na TikToku",
    linkedin: "Śledź Ferryscanner na LinkedIn",
    youtube: "Śledź Ferryscanner na YouTube",
    threads: "Śledź Ferryscanner na Threads",
  },
  de: {
    facebook: "Folge Ferryscanner auf Facebook",
    instagram: "Folge Ferryscanner auf Instagram",
    tiktok: "Folge Ferryscanner auf TikTok",
    linkedin: "Folge Ferryscanner auf LinkedIn",
    youtube: "Folge Ferryscanner auf YouTube",
    threads: "Folge Ferryscanner auf Threads",
  },
  da: {
    facebook: "Følg Ferryscanner på Facebook",
    instagram: "Følg Ferryscanner på Instagram",
    tiktok: "Følg Ferryscanner på TikTok",
    linkedin: "Følg Ferryscanner på LinkedIn",
    youtube: "Følg Ferryscanner på YouTube",
    threads: "Følg Ferryscanner på Threads",
  },
  no: {
    facebook: "Følg Ferryscanner på Facebook",
    instagram: "Følg Ferryscanner på Instagram",
    tiktok: "Følg Ferryscanner på TikTok",
    linkedin: "Følg Ferryscanner på LinkedIn",
    youtube: "Følg Ferryscanner på YouTube",
    threads: "Følg Ferryscanner på Threads",
  },
  tr: {
    facebook: "Ferryscanner'ı Facebook'ta takip et",
    instagram: "Ferryscanner'ı Instagram'da takip et",
    tiktok: "Ferryscanner'ı TikTok'ta takip et",
    linkedin: "Ferryscanner'ı LinkedIn'de takip et",
    youtube: "Ferryscanner'ı YouTube'da takip et",
    threads: "Ferryscanner'ı Threads'te takip et",
  },
  nl: {
    facebook: "Volg Ferryscanner op Facebook",
    instagram: "Volg Ferryscanner op Instagram",
    tiktok: "Volg Ferryscanner op TikTok",
    linkedin: "Volg Ferryscanner op LinkedIn",
    youtube: "Volg Ferryscanner op YouTube",
    threads: "Volg Ferryscanner op Threads",
  },
  fi: {
    facebook: "Seuraa Ferryscanneria Facebookissa",
    instagram: "Seuraa Ferryscanneria Instagramissa",
    tiktok: "Seuraa Ferryscanneria TikTokissa",
    linkedin: "Seuraa Ferryscanneria LinkedInissä",
    youtube: "Seuraa Ferryscanneria YouTubessa",
    threads: "Seuraa Ferryscanneria Threadissä",
  },
  sv: {
    facebook: "Följ Ferryscanner på Facebook",
    instagram: "Följ Ferryscanner på Instagram",
    tiktok: "Följ Ferryscanner på TikTok",
    linkedin: "Följ Ferryscanner på LinkedIn",
    youtube: "Följ Ferryscanner på YouTube",
    threads: "Följ Ferryscanner på Threads",
  },
  sq: {
    facebook: "Ndiq Ferryscanner në Facebook",
    instagram: "Ndiq Ferryscanner në Instagram",
    tiktok: "Ndiq Ferryscanner në TikTok",
    linkedin: "Ndiq Ferryscanner në LinkedIn",
    youtube: "Ndiq Ferryscanner në YouTube",
    threads: "Ndiq Ferryscanner në Threads",
  },
  et: {
    facebook: "Jälgi Ferryscannerit Facebookis",
    instagram: "Jälgi Ferryscannerit Instagramis",
    tiktok: "Jälgi Ferryscannerit TikTokis",
    linkedin: "Jälgi Ferryscannerit LinkedInis",
    youtube: "Jälgi Ferryscannerit YouTubes",
    threads: "Jälgi Ferryscannerit Threadis",
  },
  ja: {
    facebook: "FerryscannerをFacebookでフォローしてください",
    instagram: "FerryscannerをInstagramでフォローしてください",
    tiktok: "FerryscannerをTikTokでフォローしてください",
    linkedin: "FerryscannerをLinkedInでフォローしてください",
    youtube: "FerryscannerをYouTubeでフォローしてください",
    threads: "FerryscannerをThreadsでフォローしてください",
  },
  ko: {
    facebook: "Ferryscanner를 Facebook에서 팔로우하세요",
    instagram: "Ferryscanner를 Instagram에서 팔로우하세요",
    tiktok: "Ferryscanner를 TikTok에서 팔로우하세요",
    linkedin: "Ferryscanner를 LinkedIn에서 팔로우하세요",
    youtube: "Ferryscanner를 YouTube에서 팔로우하세요",
    threads: "Ferryscanner를 Threads에서 팔로우하세요",
  },
  zh: {
    facebook: "在Facebook上关注Ferryscanner",
    instagram: "在Instagram上关注Ferryscanner",
    tiktok: "在TikTok上关注Ferryscanner",
    linkedin: "在LinkedIn上关注Ferryscanner",
    youtube: "在YouTube上关注Ferryscanner",
    threads: "在Threads上关注Ferryscanner",
  },
};
