import Image from "next/image";

import { AboutUs as AboutUsType } from "../../types";

import MeetingRoom from "./assets/ferryscanner-meeting-room.png";
import LobyEntrance from "./assets/ferryscanner-loby-entrance.png";
import OpenOfficeOne from "./assets/ferryscanner-open-office-one.png";
import LobyWallArt from "./assets/ferryscanner-loby-wall-art.png";
import WaitingRoom from "./assets/ferryscanner-waiting-room.png";
import Kitchen from "./assets/ferryscanner-kitchen.png";
import OpenOfficeTwo from "./assets/ferryscanner-open-office-two.png";

import styles from "./about-us-desktop.module.css";
import Title from "../title";

const Desktop = ({ data }: { data: AboutUsType }) => {
  return (
    <>
      <div className={styles.wrapper_grid} style={{ height: 640 }}>
        <div className={styles.main_grid}>
          <div className={styles.main_image}>
            <Image
              src={MeetingRoom}
              fill
              alt={data.about_us_image_alt_text[0].image_alt_text}
              sizes="50vw"
              className={styles.image}
            />
          </div>
          <div className="relative">
            <Image
              src={LobyEntrance}
              fill
              alt={data.about_us_image_alt_text[3].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
          <div className="relative">
            <Image
              src={OpenOfficeOne}
              fill
              alt={data.about_us_image_alt_text[4].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
        </div>
        <div className={styles.side_grid}>
          <div className="relative">
            <Image
              src={LobyWallArt}
              fill
              alt={data.about_us_image_alt_text[1].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
          <div className={styles.tall_image}>
            <Image
              src={WaitingRoom}
              fill
              alt={data.about_us_image_alt_text[5].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
        </div>
        <div className={styles.side_grid}>
          <div className={styles.tall_image}>
            <Image
              src={Kitchen}
              fill
              alt={data.about_us_image_alt_text[2].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
          <div className="relative">
            <Image
              src={OpenOfficeTwo}
              fill
              alt={data.about_us_image_alt_text[6].image_alt_text}
              sizes="25vw"
              className={styles.image}
            />
          </div>
        </div>
      </div>
      <div className={styles.content} style={{ width: 540 }}>
        <Title>{data.about_us_title}</Title>
        <p>{data.about_us_description}</p>
        <a
          href={data.about_us_call_to_action_link}
          className="btn btn-outline-accent self-center"
        >
          {data.about_us_call_to_action_label}
        </a>
      </div>
    </>
  );
};

export default Desktop;
