import clsx from "clsx";
import React from "react";
import styles from "./inputAdornment.module.css";

export interface InputAdornmentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  position: "start" | "end";
  size: "md" | "lg";
}

const InputAdornment = (props: InputAdornmentProps) => {
  const { size, position, ...other } = props;

  const positionSizeClass = styles[`${position}_${size}`];

  const classes = clsx(styles.root, positionSizeClass);
  return <div className={classes} {...other} />;
};

export default InputAdornment;
