import { Route } from "@/store/routes/types";
import dayjs from "dayjs";

const getDates = (routes: Route[]): string | string[] => {
  if (routes.length === 1) {
    return routes[0].date;
  }

  return routes.map((route) => route.date);
};

export const getCalendarButtonLabel = (routes: Route[]): string => {
  const date = getDates(routes);
  if (!date) return "";

  const formatDate = (date: string) => {
    const parsedDate = dayjs(date);
    return parsedDate.isValid() ? parsedDate.format("DD MMM") : ""; // Check if the date is valid
  };

  if (typeof date === "string") {
    return formatDate(date) || ""; // Return empty string if date is invalid
  }

  const formattedDates = date.map((date) => formatDate(date));
  if (formattedDates.every((formattedDate) => !formattedDate)) {
    return ""; // Return empty string if any date is invalid
  }

  return formattedDates.join(" - ");
};
