"use client";

import React from "react";
import useEmblaCarousel from "embla-carousel-react";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";

import { Translations } from "@/global-types/translations";

import { TestimonialsItem } from "../../types";

import CarouselSlide from "./carousel-slide";
import styles from "./testimonials.module.css";

type PropType = {
  slides: TestimonialsItem[];
  translations: Translations | undefined;
};

const Carousel: React.FC<PropType> = ({ slides, translations }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    loop: true,
  });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div className={styles.carousel}>
      <div ref={emblaRef} className={styles.carousel_track}>
        <div className="-ml-12 flex">
          {slides.map((item, index) => (
            <CarouselSlide
              key={index}
              item={item}
              translations={translations}
            />
          ))}
        </div>
      </div>
      <PrevButton
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        className={styles.carousel_button}
        style={{ left: 0 }}
      />
      <NextButton
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        className={styles.carousel_button}
        style={{ right: 0 }}
      />
    </div>
  );
};

export default Carousel;
