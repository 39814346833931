"use client";

import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import Cookies from "js-cookie";
import { Translations } from "@/global-types/translations";
const Ios = dynamic(() => import("./ios"), { ssr: false });
const Android = dynamic(() => import("./android"), { ssr: false });

import { getPlatform } from "./utils";

interface Props {
  translations?: Translations;
}

const Content = ({ translations }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const userAgent =
    typeof window !== "undefined" ? window?.navigator?.userAgent : "";
  const platform = getPlatform(userAgent);
  const [isOpen, setIsOpen] = useState(!Cookies.get("downloadAppPromo"));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        toggleBanner();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleBanner = () => {
    setIsOpen(false);
    Cookies.set("downloadAppPromo", "true");
  };

  const isVisible = isOpen && Boolean(platform);

  return (
    <div ref={contentRef}>
      {platform === "ios" ? (
        <Ios
          translations={translations}
          onClose={toggleBanner}
          isOpen={isVisible}
        />
      ) : (
        <Android
          translations={translations}
          onClose={toggleBanner}
          isOpen={isVisible}
        />
      )}
    </div>
  );
};

export default Content;
