import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@/store/routes";

import { getDayStatus, getRouteIndex, isDayDisabled } from "./utils";

import styles from "./calendar.module.css";
import useSelectedDates from "./useSelectedDates";
import { Translations } from "@/global-types/translations";

interface Props {
  isMobile?: boolean;
  day: string;
  minDate: string;
  maxDate?: string;
  routeIndex: number;
  onClose?: () => void;
  translations: Translations | undefined;
}

const Day = ({
  isMobile,
  day,
  routeIndex,
  minDate,
  maxDate,
  onClose,
  translations,
}: Props) => {
  const dispatch = useDispatch();
  const routes = useSelector(selectors.getRoutes);
  const tripType = useSelector(selectors.getTripType);
  const selectedDates = useSelectedDates(routeIndex);

  const { isSelected, isBetween } = getDayStatus(day, selectedDates);

  const isDisabled = isDayDisabled({
    day,
    minDate,
    maxDate,
  });

  const handleClick = () => {
    const index = getRouteIndex(tripType, selectedDates, day, routeIndex);
    dispatch(actions.changeRouteDate({ index, value: day }));

    if (tripType === "roundTrip" && routes[1].date) {
      dispatch(actions.changeRouteDate({ index: 0, value: day }));
      dispatch(actions.changeRouteDate({ index: 1, value: "" }));
      return;
    }

    if (tripType === "roundTrip" && index === 1 && !isMobile) {
      onClose?.(); // Close on the second selection for round trips
    } else if (tripType !== "roundTrip") {
      onClose?.(); // Close immediately for one-way trips
    }
  };

  const isToday = dayjs(day).isSame(dayjs(), "day");

  if (!day) return <div className={styles.day} />;
  return (
    <button
      disabled={isDisabled}
      className={clsx(styles.day, {
        [styles.day_selected]: isSelected,
        [styles.day_range_between]: isBetween && !isSelected,
        [styles.day_disabled]: isDisabled,
      })}
      onClick={handleClick}
    >
      {isToday && (
        <span className={styles.day_today}>{translations?.today}</span>
      )}
      {dayjs(day).format("DD")}
    </button>
  );
};

export default Day;
