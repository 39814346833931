"use client";
import { Provider } from "react-redux";
import store from "@/store";
import { useEffect } from "react";
import { loadState } from "@/store/persistent-storage";
import { actions as RoutesActions } from "@/store/routes";

export default function StoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    const initializeState = () => {
      const persistedState = loadState();

      if (persistedState?.routes) {
        Object.entries(persistedState.routes).forEach(([key, value]) => {
          // Dynamically find and call the corresponding action
          const actionName =
            `change${key.charAt(0).toUpperCase()}${key.slice(1)}` as keyof typeof RoutesActions;
          const action = RoutesActions[actionName];

          if (typeof action === "function") {
            store.dispatch(action(value as never));
          }
        });
      }
    };

    initializeState();
  }, []);

  return <Provider store={store}>{children}</Provider>;
}
