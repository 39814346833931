"use client";

import React, { ButtonHTMLAttributes, forwardRef } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";
import CalendarIcon from "@/icons/calendar";

import styles from "./date-field.module.css";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isMobile?: boolean;
  label: string;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ label, isMobile, ...rest }, ref) => {
    const isMultitrip = useSelector(selectors.getIsMultitrip);

    if (isMultitrip && isMobile) {
      return (
        <button ref={ref} className={styles.button_multitrip_mobile} {...rest}>
          {label ? (
            label.split(" ").map((item, index) => {
              return (
                <span
                  key={item}
                  className={clsx("leading-none", {
                    "text-xs font-normal": index === 1,
                  })}
                >
                  {item}
                </span>
              );
            })
          ) : (
            <CalendarIcon />
          )}
        </button>
      );
    }

    return (
      <button ref={ref} className={styles.button} {...rest}>
        <span className={styles.button_label}>{label}</span>
        <CalendarIcon />
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
