"use client";

import clsx from "clsx";
import { useDispatch } from "react-redux";
import { actions } from "@/store/routes";
import TrashIcon from "@/icons/trash";

import styles from "./multitrip.module.css";

const RemoveRoute = ({ routeIndex }: { routeIndex: number }) => {
  const dispatch = useDispatch();
  if (routeIndex === 0) return <div className={styles.remove_route} />;
  return (
    <button
      onClick={() => dispatch(actions.removeRoute(routeIndex))}
      className={clsx(styles.remove_route, styles.remove_route_button)}
    >
      <TrashIcon width={20} height={20} />
    </button>
  );
};

export default RemoveRoute;
