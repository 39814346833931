"use client";

import React from "react";
import Image from "next/image";
import useEmblaCarousel from "embla-carousel-react";

import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";
import TagIcon from "@/icons/tag";

import { OffersItem } from "../../types";

import styles from "./latest-offers.module.css";

type Props = {
  offers: OffersItem[];
};

const Carousel: React.FC<Props> = ({ offers }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
  });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <>
      <div ref={emblaRef} className={styles.scrollable_area}>
        <div className={styles.scrollable_content}>
          {offers.map((item, index) => {
            return (
              <div key={index} className={styles.slide}>
                <div className={styles.card}>
                  {item.offer_image && (
                    <Image
                      alt={item.offer_image.alt}
                      src={item.offer_image.sizes.large}
                      fill
                      sizes="(min-width: 768px) 100vw, 50vw"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  )}
                  {item.offer_title && (
                    <div className={styles.badge}>
                      <TagIcon width={16} height={16} className="mt-1" />
                      <p className="font-bold">{item.offer_title}</p>
                    </div>
                  )}
                  <div className={styles.card_content}>
                    <h3 className={styles.card_title}>
                      <a href={item.offer_call_to_action_link}>
                        {item.offer_description}
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PrevButton
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        className={styles.carousel_button}
        style={{ left: -24 }}
      />
      <NextButton
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        className={styles.carousel_button}
        style={{ right: -24 }}
      />
    </>
  );
};

export default Carousel;
