import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "@/store/routes";
import clsx from "clsx";

import styles from "./swap.module.css";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  routeIndex: number;
  className?: string;
}

const Swap = ({ routeIndex, className, ...rest }: Props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(actions.swapRoutePorts(routeIndex));
  };
  return (
    <button
      onClick={handleClick}
      aria-label="Swap route"
      className={clsx(styles.root, className)}
      {...rest}
    >
      <svg viewBox="0 0 24 24" width={24} height={24} fill="none">
        <path
          d="M 6.501 16.842 L 3.511 13.854 L 16.664 13.854"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M 17.499 7.157 L 20.489 10.147 L 7.337 10.147"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </svg>
    </button>
  );
};

export default Swap;
