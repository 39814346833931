import dayjs from "dayjs";

const dateFormat = (date: string): string => dayjs(date).format("ddd, DD MMM");

export const getArrivalPortData = (arrivalPorts: string) => {
  const arrivalPortArray = arrivalPorts.split(",");
  const totalTrips =
    arrivalPortArray.length > 2 ? `(${arrivalPortArray.length} Trips)` : "";
  const arrivalPortId =
    arrivalPortArray.length > 2 ? arrivalPortArray.pop()! : arrivalPortArray[0];

  return { arrivalPortId, totalTrips };
};

export const getDate = (dates: string): string => {
  const datesArray = dates.split(",");
  const startDate = datesArray[0];
  const endDate = datesArray.length > 1 ? datesArray.pop()! : null;

  return endDate
    ? `${dateFormat(startDate)} - ${dateFormat(endDate)}`
    : dateFormat(startDate);
};
