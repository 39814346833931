import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { REDUCER_NAME } from "./constants";

const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    changeDeparturePortLocationName: (state, action: PayloadAction<string>) => {
      state.departurePortLocationName = action.payload;
    },
    changeOpenDateField: (state, action: PayloadAction<number | null>) => {
      state.openDateField = action.payload;
    },
    changeOpenReturnField: (state, action: PayloadAction<string | null>) => {
      state.openReturnField = action.payload;
    },
  },
});

export default slice;
