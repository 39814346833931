import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { REDUCER_NAME } from "./constants";
import { ChangeRoute, TripType, ChangeRoutes, Route } from "./types";
import {
  handleMultiTrip,
  handleOneWayTrip,
  handleRoundTrip,
  removeFromRoutes,
  resetArrivalIdAndDate,
  updateRouteProperty,
} from "./utils";

const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    createTripFromUrl: (
      state,
      action: PayloadAction<{
        departurePortId: string;
        arrivalPortId: string;
        date: string;
      }>,
    ) => {
      const newRoute = {
        departurePortId: action.payload.departurePortId,
        arrivalPortId: action.payload.arrivalPortId,
        date: action.payload.date,
        selectedSegmentId: "",
      };
      state.routes = [newRoute];
      state.tripType = "oneWay";
    },
    changeRouteDeparture: (state, action: PayloadAction<ChangeRoute>) => {
      const { value, index } = action.payload;

      if (state.tripType === "roundTrip") {
        updateRouteProperty({
          state,
          index,
          property: "departurePortId",
          value,
        });

        updateRouteProperty({
          state,
          index: index + 1,
          property: "arrivalPortId",
          value,
        });
      } else {
        resetArrivalIdAndDate({ index, value, state });
        updateRouteProperty({
          state,
          index,
          property: "departurePortId",
          value,
        });
      }
    },
    changeRouteArrival: (state, action: PayloadAction<ChangeRoute>) => {
      const { value, index } = action.payload;
      if (state.tripType === "roundTrip") {
        updateRouteProperty({
          state,
          index,
          property: "arrivalPortId",
          value,
        });

        updateRouteProperty({
          state,
          index: index + 1,
          property: "departurePortId",
          value,
        });
      } else {
        updateRouteProperty({
          state,
          index,
          property: "arrivalPortId",
          value,
        });
      }
    },
    changeRouteDate: (state, action: PayloadAction<ChangeRoute>) => {
      const { value, index } = action.payload;
      updateRouteProperty({
        state,
        index,
        property: "date",
        value,
      });
    },
    swapRoutePorts: (state, action: PayloadAction<number>) => {
      if (state.tripType === "roundTrip") {
        const [route, nextRoute] = [
          state.routes[action.payload],
          state.routes[action.payload + 1],
        ];

        // Swap departure and arrival ports for the first route
        [route.departurePortId, route.arrivalPortId] = [
          route.arrivalPortId,
          route.departurePortId,
        ];

        // Swap departure and arrival ports for the next route
        [nextRoute.departurePortId, nextRoute.arrivalPortId] = [
          nextRoute.arrivalPortId,
          nextRoute.departurePortId,
        ];
      } else {
        const route = state.routes[action.payload];

        // Swap departure and arrival ports
        [route.departurePortId, route.arrivalPortId] = [
          route.arrivalPortId,
          route.departurePortId,
        ];
      }
    },
    addRoute: (state) => {
      const { arrivalPortId } = state.routes[state.routes.length - 1];
      const newRoute = {
        departurePortId: arrivalPortId,
        arrivalPortId: "",
        date: "",
        selectedSegmentId: "",
      };
      state.routes = [...state.routes, newRoute];
    },
    removeRoute: (state, action: PayloadAction<number>) => {
      state.routes = removeFromRoutes(state.routes, action.payload);
    },
    changeTripType: (state, action: PayloadAction<TripType>) => {
      state.tripType = action.payload;

      if (action.payload === "oneWay") {
        state.routes = handleOneWayTrip(state.routes);
        return;
      }

      if (action.payload === "roundTrip") {
        state.routes = handleRoundTrip(state.routes);
        return;
      }
      if (action.payload === "multiTrip") {
        state.routes = handleMultiTrip(state.routes);
        return;
      }
    },
    resetRoutes: (state) => {
      state.routes.map((route) => {
        route.departurePortId = "";
        route.arrivalPortId = "";
        route.date = "";
        route.selectedSegmentId = "";
      });
    },
    changeRoutesFromCache: (state, action: PayloadAction<ChangeRoutes>) => {
      const departurePorts = action.payload.departurePorts.split(",");
      const arrivalPorts = action.payload.arrivalPorts.split(",");
      const dates = action.payload.dates.split(",");

      state.tripType = action.payload.tripType;
      state.routes = departurePorts.map((departurePortId, index) => ({
        departurePortId,
        arrivalPortId: arrivalPorts[index],
        date: dates[index],
        selectedSegmentId: "",
      }));
    },
    changeRoutes: (state, action: PayloadAction<Route[]>) => {
      // this is used by store-provider to set the routes from the Session Storage
      state.routes = action.payload;
    },
  },
});

export default slice;
