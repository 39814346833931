"use client";

import React, { useCallback } from "react";
import clsx from "clsx";
import { chunk } from "lodash";
import useEmblaCarousel from "embla-carousel-react";

import { Port } from "@/global-types/ports";

import { getUniqueCountryNames } from "./utils";

import styles from "./country-filter.module.css";
import { Translations } from "@/global-types/translations";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "@/components/embla-arrow-buttons";
interface Props {
  portsData: Port[];
  countryFilter: string;
  setCountryFilter: (country: string) => void;
  translations: Translations | undefined;
}

const CountryFilter = ({
  portsData,
  countryFilter,
  setCountryFilter,
  translations,
}: Props) => {
  const countryNames = getUniqueCountryNames(portsData);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
    align: "start",
    skipSnaps: true,
    slidesToScroll: 1,
  });

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();

    emblaApi.scrollTo(currentIndex - 5);
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    const currentIndex = emblaApi.selectedScrollSnap();

    emblaApi.scrollTo(currentIndex + 5);
  }, [emblaApi]);

  const { prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(emblaApi);

  const chunkSlides = chunk(
    countryNames.filter((name) => name),
    2,
  );

  const scrollToSlide = useCallback(
    (index: number) => {
      console.log(index);
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  if (!countryNames.length || countryNames.length === 1) return null;

  return (
    <div className={styles.root}>
      <div className="flex justify-between">
        <p>
          <b>{translations?.filter_by_country || "Filter by Country"}</b>
        </p>
        <div className="mr-2 hidden gap-2 sm:flex">
          <PrevButton
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
            className={styles.arrow_buttons}
          />
          <NextButton
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
            className={styles.arrow_buttons}
          />
        </div>
      </div>
      <div
        className="overflow-hidden"
        ref={emblaRef}
        style={{ flex: "0 0 auto" }}
      >
        <div className="flex gap-2">
          {chunkSlides.map((slide, index) => (
            <div key={index} className="flex flex-col gap-2">
              {slide.map((name, slideIndex) => {
                const isSelected = name === countryFilter;
                return (
                  <button
                    key={slideIndex}
                    className={clsx("btn btn-outline-primary btn-sm", {
                      "bg-primary bg-opacity-10": isSelected,
                    })}
                    onClick={() => {
                      if (isSelected) {
                        setCountryFilter("");
                        return;
                      }
                      setCountryFilter(name);
                      scrollToSlide(index);
                    }}
                    style={{
                      flex: "0 0 auto",
                      minWidth: 0,
                      maxWidth: "100%",
                    }}
                  >
                    {name}
                  </button>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountryFilter;
