import React from "react";
import ReactMarkdown, { MarkdownToJSX } from "markdown-to-jsx";
import styles from "./title.module.css";
import clsx from "clsx";

interface Props {
  children: string;
  options?: MarkdownToJSX.Options;
  className?: string;
}

const Title = ({ children, options, className }: Props) => {
  return (
    <ReactMarkdown
      options={{
        overrides: {
          h1: {
            component: "h1",
            props: {
              className: clsx(styles.h1, className),
            },
          },
          h2: {
            component: "h2",
            props: { className: clsx(styles.h2, className) },
          },
        },
        ...options,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Title;
