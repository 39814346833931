"use client";

import React, { useState } from "react";
import clsx from "clsx";
import { subscribe } from "@/data/subscribe/queries";
import { Translations } from "@/global-types/translations";
import FormField from "@/components/ui/form-field";

import styles from "./newsletter.module.css";
import { handleResponse } from "./utils";

const Newsletter = ({
  translations,
  lang,
}: {
  translations: Translations | undefined;
  lang: string;
}) => {
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const submit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setHasError(false);
    setStatusMessage("");

    const response = await subscribe(email, lang);
    const { error, message } = handleResponse(response);
    setHasError(error);
    setStatusMessage(message);
  };

  const statusTextClasses = clsx([styles.text], {
    [styles.success_text]: !hasError,
    [styles.error_text]: hasError,
  });

  return (
    <section className="mb-10">
      <div className="container px-0 sm:px-4">
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>
              {translations?.homepage_newsletter_title || "Let's keep"}
              <span>
                {translations?.homepage_newsletter_subtitle || "in touch!"}
              </span>
            </div>
            <p className={styles.description}>
              {translations?.homepage_newsletter_subscription_description ||
                "Stay informed about ferry ticket discounts and recent blog articles as well as exciting company and industry news."}
            </p>
            <div className={styles.form}>
              <FormField
                id="newsletter"
                inputSize="lg"
                placeholder={
                  translations?.homepage_newsletter_subscription_enter_email ||
                  "Enter your email"
                }
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="btn btn-accent" onClick={submit}>
                {translations?.homepage_newsletter_subscription_button ||
                  "Subscribe"}
              </button>
            </div>
          </div>
        </div>
        {statusMessage && (
          <div className={statusTextClasses}>
            <p>{statusMessage}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Newsletter;
