import { Translations } from "@/global-types/translations";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

const months = (translations: Translations) => [
  translations.january || "January",
  translations.february || "February",
  translations.march || "March",
  translations.april || "April",
  translations.may || "May",
  translations.june || "June",
  translations.july || "July",
  translations.august || "August",
  translations.september || "September",
  translations.october || "October",
  translations.november || "November",
  translations.december || "December",
];

const monthsShort = (translations: Translations) => [
  translations.jan || "Jan",
  translations.feb || "Feb",
  translations.mar || "Mar",
  translations.apr || "Apr",
  translations.may || "May",
  translations.jun || "Jun",
  translations.jul || "Jul",
  translations.aug || "Aug",
  translations.sep || "Sep",
  translations.oct || "Oct",
  translations.nov || "Nov",
  translations.dec || "Dec",
];

const weekDays = (translations: Translations) => [
  translations.sunday || "Sunday",
  translations.monday || "Monday",
  translations.tuesday || "Tuesday",
  translations.wednesday || "Wednesday",
  translations.thursday || "Thursday",
  translations.friday || "Friday",
  translations.saturday || "Saturday",
];

const weekDaysShort = (translations: Translations) => [
  translations.sun || "Sun",
  translations.mon || "Mon",
  translations.tue || "Tue",
  translations.wed || "Wed",
  translations.thu || "Thu",
  translations.fri || "Fri",
  translations.sat || "Sat",
];

const getTranslatedTermsDayJS = (translations: Translations) => {
  return {
    months: months(translations),
    monthsShort: monthsShort(translations),
    weekdays: weekDays(translations),
    weekdaysShort: weekDaysShort(translations),
  };
};

export const setCalendarTranslations = (
  translations: Translations | undefined,
) => {
  if (!translations) {
    return;
  }

  const currentLocale = dayjs.locale();
  dayjs.updateLocale(currentLocale, getTranslatedTermsDayJS(translations));
};
