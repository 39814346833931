"use client";

import React from "react";

import { AboutUs as AboutUsType } from "../../types";

import Mobile from "./mobile";
import Desktop from "./desktop";

import styles from "./about-us.module.css";
import Polygon from "./polygon";
import Circle from "./circle";

interface Props {
  isMobile: boolean;
  data: AboutUsType;
}

const AboutUs = ({ data, isMobile }: Props) => {
  return (
    <section className={styles.root}>
      <div className="container relative">
        {!isMobile && (
          <>
            <Polygon />
            <Circle />
          </>
        )}
        {!isMobile ? <Desktop data={data} /> : <Mobile data={data} />}
      </div>
    </section>
  );
};

export default AboutUs;
