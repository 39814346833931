import { RootState } from "@/store/types";
import { createSlice } from "./utils";
import { persistent as routesPersistentState } from "./routes/persistent-state";

export const persistent = (state: RootState) => {
  return [routesPersistentState].reduce(
    (persistentState, constants) => ({
      ...persistentState,
      ...createSlice(state, constants),
    }),
    {},
  );
};
