import { Port } from "@/global-types/ports";

export const getMatchingPorts = (
  portsData: Port[],
  departurePortId: string,
): Port[] => {
  const foundPort = portsData.find(
    (port) => port.ferryOwnLocationID === departurePortId,
  );

  if (!foundPort || !foundPort.dependentPorts) return [];

  const filteredFerries = portsData.filter((port) =>
    foundPort.dependentPorts.includes(port.ferryOwnLocationID),
  );

  return filteredFerries;
};
