import { State } from "./types";

const initialRoute = {
  departurePortId: "",
  arrivalPortId: "",
  date: "",
  selectedSegmentId: "",
};

const initialState: State = {
  tripType: "oneWay",
  routes: [initialRoute],
};

export default initialState;
