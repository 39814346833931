import React from "react";

const Globe = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M8 16c1.775 0 3.214-3.358 3.214-7.5C11.214 4.358 9.775 1 8 1S4.786 4.358 4.786 8.5C4.786 12.642 6.225 16 8 16ZM.5 8.5h15" />
    <path d="M8 16A7.5 7.5 0 1 0 8 1a7.5 7.5 0 0 0 0 15Z" />
  </svg>
);
export default Globe;
