import { OPTIONS } from "@/data/constants";

import * as C from "./constants";
import { SubscribeResponse } from "@/global-types/subscribe";
import { SUPPORTED_LANGUAGES } from "@/constants/language";

export const subscribe = async (
  email: string,
  lang: string,
): Promise<SubscribeResponse> => {
  const languageCode = SUPPORTED_LANGUAGES[lang]?.code;
  const url = `${C.SUBSCRIBE_URL}${email}&locale=${languageCode}`;

  const response = await fetch(url, {
    method: "GET",
    ...OPTIONS,
  });

  return response.json();
};
