"use client";

import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useSelector, useDispatch } from "react-redux";
import {
  selectors as searchMaskSelectors,
  actions as searchMaskActions,
} from "@/store/searchMask";

import { selectors as routesSelectors } from "@/store/routes";

import Button from "./button";
import Content from "./content";

import styles from "./date-field.module.css";
import { Translations } from "@/global-types/translations";

const Dropdown = dynamic(() => import("@/components/dropdown"), { ssr: false });

interface Props {
  label: string;
  routeIndex: number;
  minDate: string;
  maxDate?: string;
  disabled?: boolean;
  translations: Translations | undefined;
}

const Desktop = ({
  label,
  routeIndex,
  minDate,
  maxDate,
  disabled,
  translations,
}: Props) => {
  const dispatch = useDispatch();
  const openDateField = useSelector(searchMaskSelectors.getOpenDateField);
  const tripType = useSelector(routesSelectors.getTripType);
  const routes = useSelector(routesSelectors.getRoutes);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (openDateField === routeIndex) {
      dispatch(searchMaskActions.changeOpenDateField(null));
    }
  };

  useEffect(() => {
    if (openDateField === routeIndex && buttonRef.current) {
      buttonRef.current.click();
      buttonRef.current.focus();
    }
  }, [openDateField, routeIndex]);

  useEffect(() => {
    if (
      !anchorEl &&
      tripType === "roundTrip" &&
      !routes[1]?.date &&
      buttonRef.current
    ) {
      buttonRef.current.click();
      buttonRef.current.focus();
    }
  }, [anchorEl, tripType, routes]);

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        disabled={disabled}
        label={label}
      />
      {open && (
        <Dropdown
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          className={styles.dropdown}
        >
          <Content
            routeIndex={routeIndex}
            minDate={minDate}
            maxDate={maxDate}
            onClose={handleClose}
            translations={translations}
          />
        </Dropdown>
      )}
    </>
  );
};

export default Desktop;
