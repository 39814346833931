import React from "react";
import clsx from "clsx";
import styles from "./helperText.module.css";

export interface HelperTextProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  color: "default" | "error" | "success";
}

const HelperText = (props: HelperTextProps) => {
  const { color = "default", ...other } = props;

  const colorClass = styles[`color_${color}`];
  const classes = clsx(styles.root, colorClass);

  return <p className={classes} {...other} />;
};

export default HelperText;
