"use client";

import dynamic from "next/dynamic";
const BubbleTitle = dynamic(() => import("./bubble-title"), { ssr: false });

type Props = { title: string; description: string };

const BubbleTitleClient = ({ title, description }: Props) => {
  return <BubbleTitle title={title} description={description} />;
};

export default BubbleTitleClient;
