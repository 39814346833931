import { Port } from "@/global-types/ports";

export const getUniqueCountryNames = (portsData: Port[]): string[] => {
  const countryNames = new Set<string>();

  portsData.forEach((port) => {
    countryNames.add(port.countryName);
  });

  return Array.from(countryNames).sort((a, b) => a.localeCompare(b));
};
