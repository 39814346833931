"use client";

import React from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import ArrowRight from "@/icons/arrow-right";
import Clock from "@/icons/clock";
import { Translations } from "@/global-types/translations";

import type { Route as TRoute } from "../../types";

import { getRouteLink } from "./utils";

import styles from "./route.module.css";

const PopularRoute = ({
  route,
  translations,
}: {
  route: TRoute;
  translations: Translations | undefined;
}) => {
  const pathname = usePathname();
  const routeLink = getRouteLink(pathname, route);
  return (
    <div className={styles.card}>
      <a
        className={styles.destination}
        href={route.route_url}
        title={`${route.departure_name} to ${route.arrival_name}`}
      >
        <span className={styles.destination_name}>{route.departure_name}</span>
        <ArrowRight className={styles.arrow_icon} />
        <span className="sr-only"> to </span>
        <span className={styles.destination_name}>{route.arrival_name}</span>
      </a>
      <div className={styles.duration}>
        <Clock className={styles.duration_icon} />
        <p>{route.averageDuration}</p>
      </div>
      <div className={styles.company}>
        <Image
          width={40}
          height={40}
          src={route.companyLogo}
          alt={route.fastestCompanyName}
          className={styles.company_logo}
        />
        <div>
          <p className={styles.company_name}>{route.fastestCompanyName}</p>
          <p className={styles.vessel_name}>{route.fastestVesselName}</p>
        </div>
      </div>
      <div className={styles.price_container}>
        <p className={styles.price}>{route.price_from} €</p>
        <a className="btn btn-outline-accent" href={routeLink} rel="nofollow">
          {translations?.find_tickets || "Find Tickets"}
        </a>
      </div>
    </div>
  );
};

export default PopularRoute;
