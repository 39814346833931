import React from "react";
import clsx from "clsx";

import styles from "./skeleton.module.css";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  bg?: "light" | "dark";
}
const Skeleton = ({ bg = "light", ...rest }: Props) => {
  return (
    <div
      className={clsx(styles.root, {
        [styles.light]: bg === "light",
        [styles.dark]: bg === "dark",
      })}
      {...rest}
    />
  );
};

export default Skeleton;
