"use client";

import React from "react";
import { Translations } from "@/global-types/translations";

import Actions from "./actions";
import { LINKS } from "./constants";
import useCurrencies from "@/components/navbar/useCurrencies";

interface Props {
  lang: string;
  translations: Translations;
}

const Desktop = ({ lang, translations }: Props) => {
  const { defaultCurrency, availableCurrencies } = useCurrencies();

  if (!defaultCurrency || !availableCurrencies.length) {
    return;
  }

  return (
    <div className="flex items-center">
      {LINKS.map((link) => (
        <a
          className="btn btn-text-primary"
          key={link.label}
          href={`/${lang}${link.href}`}
        >
          {translations?.[link.term] || link.label}
        </a>
      ))}
      <Actions
        translations={translations}
        lang={lang}
        availableCurrencies={availableCurrencies}
        defaultCurrency={defaultCurrency}
      />
    </div>
  );
};

export default Desktop;
