import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./formField.module.css";

export interface FormFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  inputSize: "md" | "lg";
  color?: "default" | "error" | "success";
  startAdornment?: boolean;
  endAdornment?: boolean;
  multiline?: boolean;
  rows?: number;
}

const FormField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FormFieldProps
>((props, ref) => {
  const {
    inputSize,
    color = "default",
    startAdornment,
    endAdornment,
    multiline,
    rows,
    ...other
  } = props;

  const sizeClass = styles[`size_${inputSize}`];
  const colorClass = styles[`color_${color}`];
  const adornmentClass = clsx({
    [styles[`with-startAdornment-${inputSize}`]]: startAdornment,
    [styles[`with-endAdornment-${inputSize}`]]: endAdornment,
  });

  const classes = clsx(styles.root, colorClass, sizeClass, adornmentClass);

  if (multiline) {
    return (
      <textarea
        ref={ref as React.Ref<HTMLTextAreaElement>}
        rows={rows}
        className={classes}
        {...other}
      />
    );
  }

  return (
    <input
      ref={ref as React.Ref<HTMLInputElement>}
      className={classes}
      {...other}
    />
  );
});

FormField.displayName = "FormField";

export default FormField;
