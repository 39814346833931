"use client";

import React from "react";
import Skeleton from "../skeleton";

const FooterSkeleton = () => {
  return (
    <footer className="my-10">
      <div className="container flex flex-col gap-6 md:gap-10">
        <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-6">
          <div className="lg:col-span-2">
            <Skeleton bg="dark" style={{ width: 128, height: 24 }} />
            <div className="mt-4 flex flex-col gap-2">
              {Array.from({ length: 3 }).map((_, idx) => (
                <Skeleton
                  key={idx}
                  bg="light"
                  style={{ width: 192, height: 16 }}
                />
              ))}
            </div>
          </div>
          {Array.from({ length: 4 }).map((_, idx) => (
            <div key={idx}>
              <Skeleton bg="dark" style={{ width: 128, height: 24 }} />
              <div className="mt-4 flex flex-col gap-2">
                {Array.from({ length: 2 }).map((_, idx) => (
                  <Skeleton
                    key={idx}
                    bg="light"
                    style={{ height: 16, maxWidth: 192 }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div className="flex flex-wrap items-center justify-center gap-6 md:justify-between">
          {Array.from({ length: 2 }).map((_, idx) => (
            <Skeleton key={idx} bg="light" style={{ height: 16, width: 192 }} />
          ))}
        </div>
        <hr />
        <Skeleton bg="dark" style={{ width: 128, height: 24 }} />
      </div>
    </footer>
  );
};

export default FooterSkeleton;
