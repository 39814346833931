import(/* webpackMode: "eager" */ "/usr/src/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
