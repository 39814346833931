"use client";

import React, { useCallback } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";

type Props = {
  label: string;
};

const OpenChatButton = ({ label }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);
      return params.toString();
    },
    [searchParams],
  );

  return (
    <Link
      scroll={false}
      className="btn btn-accent"
      href={pathname + "?" + createQueryString("openChat", "1")}
    >
      {label}
    </Link>
  );
};

export default OpenChatButton;
