import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";
import { TripType } from "@/store/routes/types";

import TripTypes from "../trip-types";

import Calendar from "./calendar";

import styles from "./date-field.module.css";
import { Translations } from "@/global-types/translations";
import { TRIP_TYPE_TERMS } from "./contansts";

interface Props {
  isMobile?: boolean;
  routeIndex: number;
  minDate: string;
  maxDate?: string;
  onClose: () => void;
  translations: Translations | undefined;
}

const Content = ({
  isMobile,
  routeIndex,
  minDate,
  maxDate,
  onClose,
  translations,
}: Props) => {
  const isMultitrip = useSelector(selectors.getIsMultitrip);
  const items = Object.entries(TRIP_TYPE_TERMS).map(
    ([key, { term, fallbackTranslation }]) => ({
      value: key as TripType,
      label: translations?.[term] || fallbackTranslation,
    }),
  );

  return (
    <div className={styles.content}>
      {!isMultitrip && (
        <TripTypes items={items} className="self-center border" />
      )}
      <Calendar
        isMobile={isMobile}
        routeIndex={routeIndex}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        translations={translations}
      />
    </div>
  );
};

export default Content;
