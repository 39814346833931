import React, { useState } from "react";
import Image from "next/image";
import clsx from "clsx";

import { Translations } from "@/global-types/translations";

import GoogleLogo from "./icons/google-logo.svg";
import TrustpilotLogo from "./icons/trustpilot-logo.svg";
import RenderStars from "./render-stars";

import styles from "./testimonials.module.css";

import { TestimonialsItem } from "../../types";

interface Props {
  item: TestimonialsItem;
  translations: Translations | undefined;
}

const CarouselSlide = ({ item, translations }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const isTextTruncated = item.testimonial_description.length > 150;
  const textclasses = clsx({
    [styles.description_short]: !expanded && isTextTruncated,
  });
  const isGoogle = item.testimonial_source.toLocaleLowerCase() === "google";

  return (
    <div className={styles.carousel_item}>
      <div>
        {isGoogle ? (
          <Image src={GoogleLogo} alt="Google" />
        ) : (
          <Image src={TrustpilotLogo} alt="Trustpilot" />
        )}
      </div>
      <p className={styles.title}>{item.testimonial_title}</p>
      <p className={textclasses}>{item.testimonial_description}</p>
      {isTextTruncated && (
        <button
          className="btn btn-text-primary btn-sm"
          onClick={handleExpandClick}
          style={{
            justifyContent: "flex-start",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {expanded
            ? translations?.read_less || "Read Less"
            : translations?.read_more || "Read More"}
        </button>
      )}
      <div className="mt-auto flex items-center justify-between">
        <div>
          <p className="font-bold">{item.testimonial_name}</p>
          <p>{item.testimonial_date}</p>
        </div>
        <RenderStars
          stars={parseFloat(item.testimonial_stars)}
          isGoogle={isGoogle}
        />
      </div>
    </div>
  );
};

export default CarouselSlide;
