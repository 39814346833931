import { useEffect, useState } from "react";
import { getCurrencyData } from "@/data/user/queries";

const useCurrencies = () => {
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);

  useEffect(() => {
    const getData = async () => {
      const { availableCurrencies, defaultCurrency } = await getCurrencyData();
      setAvailableCurrencies(availableCurrencies);
      setDefaultCurrency(defaultCurrency);
    };

    getData();
  }, []);

  return { defaultCurrency, availableCurrencies };
};

export default useCurrencies;
